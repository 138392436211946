import React, {useEffect, useState} from 'react';
import './styles/Services.css';

import webDesignImage from '../assets/images/webDesignNew.png'
import itConsultingImage from '../assets/images/techSupportMan.png'
import domainImage from '../assets/images/domainImage.png'
import visibilityImage from '../assets/images/onlinePresence.png'

import countryRoadsImage from '../assets/images/countryRoads.jpg';
import servicesBG from '../assets/images/servicesBG.jpg';

import { Helmet } from 'react-helmet-async';

function Services() {
  const [mounted, setMounted] = useState(false);

  const services = [
    {
        name: "Website Development (or Redevelopment) and Design",
        description: "We specialize in creating new websites and revamping existing ones to be contemporary, engaging, and in line with current web standards. Our focus is on visually stunning and robust web applications that are fully responsive and quick, ensuring seamless cross-device experiences. Along with creative and performant design, we also use various third-party tools, such as Adobe Photoshop and Illustrator to make sure we cover every detail. Whether building from scratch or updating your site, we deliver an online presence that's impactful and captivating.",
        image: webDesignImage,
        alt: "Web developer working on multiple websites."
    },
    {
        name: "Continued Technical Support and Maintenance",
        description: "We offer comprehensive technical support and maintenance around the clock, allowing you to focus on your business while we fully manage your website and online presence. From routine updates to swift problem resolution, we ensure flawless website functionality. Our approach lets you concentrate solely on your business, knowing your digital presence is in good hands.",
        image: itConsultingImage,
        alt: "Two people sitting at computers communicating."
    },
    {
        name: "Domain Procurement and Hosting Management",
        description: "We streamline your website's launch by managing domain procurement and web hosting, getting your website online with ease. Like magic, we seamlesly bring your vision to life, performing all the needed behind-the-scenes work of making sure your website is up and running as smoothly as possible and empowering your business in no time. ",
        image: domainImage,
        alt: "Developer managing multiple domains and hosting plans."
    },
    {
        name: "Digital Visibility and Online Presence Improvement",
        description: "Our service integrates content creation and SEO (Search Engine Optimization) to elevate your digital footprint. We develop compelling blog content designed to stand out, optimizing your site for search engines and improving your Google presence. On top of this, we can help better optimize your Google Business Profile for ranking well in Google search and reaching more customers. We can also setup or help setup your site's Google Analytics, Google Tags, and Google Search Console. This strategy not only enriches your site with relevant content but also expands your digital reach by opening new avenues into your site.",
        image: visibilityImage,
        alt: "Man hovering hourglass over different users, so as to check their online presence."
    }
  ];

  useEffect(() => {
    // Set mounted to true to indicate that initial rendering is done
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!('IntersectionObserver' in window)) {
        console.warn('IntersectionObserver is not supported by this browser.');
        // Fallback logic here if needed
        return;
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('slide-up');
                const img = entry.target.querySelector('.service-image img');
                if (img) {
                    setTimeout(() => {
                        img.classList.add('scale-up');
                    }, 500);
                }
            } else {
                const img = entry.target.querySelector('.service-image img');
                if (img) {
                    img.classList.remove('scale-up');
                }
            }
        });
    }, { threshold: 0.04 });

    document.querySelectorAll('.service-entry').forEach(serviceEntry => {
        observer.observe(serviceEntry);
    });

    return () => observer.disconnect();
}, [mounted]);



  return (
    <main className="services-page">
      <Helmet>
        <title>Our Services - WattsWebWorks</title>
        <meta name="description" content="Explore our wide range of web services designed to boost your online presence, from website development to SEO and beyond."/>
        <meta property="og:title" content="Our Services - WattsWebWorks" />
        <meta property="og:description" content="Explore our wide range of web services designed to boost your online presence, from website development to SEO and beyond." />
        <meta property="og:url" content="https://www.wattswebworks.com/services" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="WattsWebWorks" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Service",
            "serviceType": "Web Development and Design Services",
            "provider": {
              "@type": "LocalBusiness",
              "name": "WattsWebWorks",
              "image": "https://www.wattswebworks.com/logo512.png",
              "url": "https://www.wattswebworks.com/services",
              "logo": "https://www.wattswebworks.com/logo512.png",
              "description": "WattsWebWorks offers comprehensive web development and design services to elevate your online presence, including website development, technical support, domain management, and digital visibility enhancement."
            },
            "areaServed": {
              "@type": "Country",
              "name": "USA"
            },
            "hasOfferCatalog": {
              "@type": "OfferCatalog",
              "name": "Our Web Development and Design Services",
              "itemListElement": [
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Website Development (or Redevelopment) and Design",
                    "description": "Creating new websites and revamping existing ones to align with current web standards, focusing on responsive and performant web applications."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Continued Technical Support and Maintenance",
                    "description": "Comprehensive technical support and maintenance to ensure flawless website functionality."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Domain Procurement and Hosting Management",
                    "description": "Streamlining website launch by managing domain procurement and hosting to ensure a fast, accessible, and reliable website."
                  }
                },
                {
                  "@type": "Offer",
                  "itemOffered": {
                    "@type": "Service",
                    "name": "Digital Visibility and Online Presence Improvement",
                    "description": "Enhancing online presence through content creation, SEO, and digital marketing strategies."
                  }
                }
              ]
            }
          })}
        </script>
      </Helmet>

      <div
          className="page-title"
          aria-label="Our Services Page Title"
          style={{ backgroundImage: `url(${countryRoadsImage})` }}
      >
          <h1 className="title-text">Our Services</h1>
      </div>
      <div className="section-background blue top services-page" aria-labelledby="services-page-title" style={{ backgroundImage: `url(${servicesBG})` }}>
        {services.map((service, index) => (
          <div key={index} className="service-entry">
            <div className="service-info">
              <h2 className="service-title">{service.name}</h2>
              <hr className="service-separator" />
              <p className="service-description">{service.description}</p>
            </div>
            <div className="service-image">
              <img src={service.image} alt={service.alt} loading={index === 0 ? 'eager' : undefined}/>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}

export default Services;

