import React from 'react';
import './Icon.css';

const ConsultingIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(750,3300) scale(0.85,-0.85)" stroke="black" strokeWidth="55" fill="none">
            <path d="M522 2387 c-22 -23 -22 -29 -22 -314 0 -268 1 -291 18 -306 16 -15 45 -17 198 -17 l180 0 64 -65 c63 -64 65 -65 119 -65 l54 0 -5 65 -6 65 64 0 64 0 0 33 0 32 -92 -2 -93 -2 -1 -58 c0 -32 -1 -62 -2 -68 -1 -5 -33 21 -71 59 l-68 68 -182 -2 -181 -1 0 268 0 268 392 -2 391 -2 -2 -141 -2 -140 35 0 36 0 0 150 c0 147 -1 152 -25 175 l-24 25 -409 0 -409 0 -21 -23z"/>
            <path d="M1615 2385 c-24 -23 -25 -28 -25 -175 l0 -150 36 0 35 0 -2 140 -2 141 391 2 392 2 0 -268 0 -268 -181 1 -182 2 -68 -68 c-38 -38 -70 -64 -71 -59 -1 6 -2 36 -2 68 l-1 58 -93 2 -92 2 0 -32 0 -33 63 0 64 0 -1 -62 -1 -63 50 -3 c50 -3 51 -2 115 63 l64 65 182 0 c163 0 183 2 197 18 15 16 17 53 17 310 0 287 0 292 -22 312 -21 19 -33 20 -430 20 l-409 0 -24 -25z"/>
            <path d="M810 2096 c0 -35 1 -36 36 -36 34 0 35 1 32 33 -3 28 -7 32 -35 35 -32 3 -33 2 -33 -32z"/>
            <path d="M939 2125 c-1 -3 -1 -17 -2 -32 -1 -24 3 -28 31 -31 31 -3 32 -2 32 32 0 33 -2 36 -30 36 -16 0 -30 -2 -31 -5z"/>
            <path d="M1060 2096 c0 -35 1 -36 36 -36 34 0 35 1 32 33 -3 28 -7 32 -35 35 -32 3 -33 2 -33 -32z"/>
            <path d="M1877 2123 c-4 -3 -7 -19 -7 -35 0 -25 3 -28 35 -28 33 0 35 2 35 35 0 32 -3 35 -28 35 -16 0 -32 -3 -35 -7z"/>
            <path d="M2000 2095 c0 -35 1 -36 32 -33 28 3 32 7 33 33 0 27 -4 30 -32 33 -32 3 -33 2 -33 -33z"/>
            <path d="M2127 2123 c-4 -3 -7 -19 -7 -35 0 -25 3 -28 35 -28 33 0 35 2 35 35 0 32 -3 35 -28 35 -16 0 -32 -3 -35 -7z"/>
            <path d="M1425 2016 c-41 -18 -92 -77 -106 -124 -6 -21 -10 -77 -8 -125 4 -78 7 -90 37 -132 50 -71 44 -82 -76 -135 -116 -52 -157 -84 -172 -138 -7 -23 -10 -113 -8 -232 l3 -194 30 0 30 0 2 204 c1 135 6 212 14 226 10 20 145 94 170 94 6 0 8 -17 3 -45 l-6 -45 58 0 c45 0 63 5 81 22 l23 21 23 -21 c18 -17 36 -22 81 -22 l58 0 -6 45 c-5 28 -3 45 3 45 25 0 160 -74 170 -94 8 -14 13 -91 14 -226 l2 -204 30 0 30 0 3 194 c2 114 -1 210 -7 230 -16 53 -61 90 -174 140 -120 54 -126 65 -75 136 30 42 33 54 37 132 2 47 -2 103 -8 124 -15 48 -66 107 -108 124 -41 17 -109 17 -148 0z m152 -77 c37 -35 49 -75 48 -160 -1 -61 -6 -82 -24 -107 -12 -18 -27 -32 -32 -32 -5 0 -9 -31 -9 -69 0 -60 2 -69 20 -74 15 -4 20 -14 20 -36 0 -41 -16 -39 -62 6 l-38 37 -38 -37 c-46 -45 -62 -47 -62 -8 0 21 6 32 20 36 15 5 20 15 20 41 0 32 2 34 20 24 27 -15 40 -5 40 31 0 19 -5 29 -15 29 -27 0 -71 28 -90 56 -21 33 -27 164 -10 209 7 17 27 44 46 59 28 24 40 27 77 23 26 -3 53 -14 69 -28z"/>
            <path d="M2190 1701 c0 -3 0 -192 0 -418 l0 -413 -205 0 -205 0 0 140 0 140 -30 0 -30 0 0 -137 0 -138 -220 0 -220 0 0 143 0 142 -30 0 -30 0 0 -143 0 -143 -205 1 -205 0 0 405 1 405 -30 3 -31 3 0 -440 0 -441 750 0 750 0 0 448 0 449 -30 0 c-16 0 -30 -3 -30 -6z"/>
            <path d="M622 1220 l3 -470 -62 0 -63 0 0 -38 c0 -33 8 -45 58 -95 l58 -57 885 0 885 0 57 58 c49 49 57 63 57 95 l0 37 -62 0 -63 0 3 470 3 470 -35 0 -36 0 0 -470 0 -470 -361 0 -360 0 3 -30 3 -31 420 1 420 0 -37 -30 -37 -31 -861 0 -861 0 -37 31 -37 30 420 0 420 -1 3 31 3 30 -360 0 -361 0 0 470 0 470 -36 0 -35 0 3 -470z"/>
            <path d="M1470 1128 l0 -189 31 3 30 3 0 185 0 185 -31 1 -30 2 0 -190z"/>
            <path d="M1470 717 c0 -31 3 -34 30 -34 27 0 30 3 30 34 0 30 -3 33 -30 33 -27 0 -30 -3 -30 -33z"/>
        </g>
      </svg>
    </div>
  );
};

export default ConsultingIcon;
