import React, { useEffect, useState } from 'react';
import './styles/Blog.css';
import BlogCard from '../components/BlogCard';
import { Helmet } from 'react-helmet-async';
import axios from './../api/axios';

export default function Blog() {
  // State to hold the fetched blog posts
  const [blogPostsData, setBlogPostsData] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');

  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  // Function to fetch blog posts from the backend
  const fetchBlogPosts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-blogs`);
      const data = response.data;
  
      // Simulate loading delay
      setTimeout(() => {
        setBlogPostsData(data);
  
        const allCategories = new Set();
        const allTags = new Set();
        data.forEach(post => {
          post.categories && post.categories.split(',').forEach(category => allCategories.add(category.trim()));
          post.tags && post.tags.split(',').forEach(tag => allTags.add(tag.trim()));
        });
        setCategories(Array.from(allCategories));
        setTags(Array.from(allTags));
        setLoading(false); // Set loading to false after data is fetched and delay
      }, 50);
    } catch (error) {
      console.error("Failed to fetch blog posts:", error);
      setLoading(false); // Set loading to false even if there's an error
    }
  };
  

  // useEffect to call fetchBlogPosts when the component mounts
  useEffect(() => {
    fetchBlogPosts();
  }, []);

  const filteredBlogPosts = blogPostsData.filter((post) => {
    return (
      (post.title.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (selectedCategory ? post.categories.split(',').includes(selectedCategory) : true) &&
      (selectedTag ? post.tags.split(',').includes(selectedTag) : true)
    );
  });

  return (
    <main>
      <Helmet>
        <title>Blog - WattsWebWorks</title>
        <meta name="description" content="Stay updated with the latest news, insights, and trends in web design and digital marketing from WattsWebWorks." />
        <meta property="og:title" content="Blog - WattsWebWorks" />
        <meta property="og:description" content="Stay updated with the latest news, insights, and trends in web design and digital marketing from WattsWebWorks." />
        <meta property="og:url" content="https://www.wattswebworks.com/blog" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="WattsWebWorks" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog - WattsWebWorks" />
        <meta name="twitter:description" content="Stay updated with the latest news, insights, and trends in web design and digital marketing from WattsWebWorks." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Blog",
            "name": "WattsWebWorks Blog",
            "url": "https://www.wattswebworks.com/blog",
            "description": "Stay updated with the latest news, insights, and trends in web design and digital marketing from WattsWebWorks.",
            "publisher": {
              "@type": "Organization",
              "name": "WattsWebWorks",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.wattswebworks.com/logo512.png"
              }
            }
          })}
        </script>
      </Helmet>
      <div className='blogs-title-wrapper'>
        <div className='blogs-title'>
          <pre>
                    {"                                                                                                                                          \n" +
                    "                                                                                                                                          \n" +
                    " ?88   d8p  d80                                   ?88   d8p  d8p         d8b      ?88   d8p  d8p                   d8b                   d8b888b  d8b                   \n" +
                    " ?88   d8p  d80             d8P     d8P           ?88   d8p  d8p         ?88      ?88   d8p  d8p                   ?88                    ?88  ?8b 88P                   \n" +
                    " ?88   d8p  d80          d888888Pd888888P         ?88   d8p  d8p         88b      ?88   d8p  d8p                   88b                    88b   d8 d88                    \n" +
                    " ?88   d8P  d8P d888b8b    ?88'    ?88'   .d888b, ?88   d8P  d8P d8888b  888888b  ?88   d8P  d8P d8888b   88bd88b  888  d88' .d888b,      888888b  888   d8888b  d888b8b  \n" +
                    " d88  d8P' d8P'd8P' ?88    88P     88P    ?8b,    d88  d8P' d8P'd8b_,dP  88P `?8b d88  d8P' d8P'd8P' ?88  88P'  `  888bd8P'  ?8b,         88P `?8b ?88  d8P' ?88d8P' ?88  \n" +
                    " ?8b ,88b ,88' 88b  ,88b   88b     88b      `?8b  ?8b ,88b ,88' 88b     d88,  d88 ?8b ,88b ,88' 88b  d88 d88      d88888b      `?8b      d88,  d88 88b 88b  d8888b  ,88b \n" +
                    " `?888P'888P'  `?88P'`88b  `?8b    `?8b  `?888P'  `?888P'888P'  `?888P'd88'`?88P' `?888P'888P'  `?8888P'd88'     d88' `?88b,`?888P'     d88'`?88P'  88b`?8888P'`?88P'`88b\n" +
                    "                                                                                                                                                                      )88\n" +
                    "                                                                                                                                                                     ,88P\n" +
                    "                                                                                                                                                                 `?8888P"
          }
          </pre>

        </div>
        <div className='blog-description'>
          <p>The latest insights, trends, and tips in web design and tech.</p>
        </div>
      </div>
      <div className='blog-inputs-wrapper'>
        <div className='blog-inputs'>
          <div className='blog-search-wrapper'>
            <input
              id='blog-search'
              className='blog-search'
              type='search'
              placeholder='Search blogs...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            {!searchQuery ? <i className="fa-solid fa-magnifying-glass search-icon"/> : ""}
          </div>
          <div className='tag-cat-selectors'>
            <select name="categories" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} aria-label="Select category">
              <option value="">All Categories</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <select name="tags" value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)} aria-label="Select tag">
              <option value="">All Tags</option>
              {tags.map(tag => (
                <option key={tag} value={tag}>{tag}</option>
              ))}
            </select>
          </div>
        </div>
        <hr className="blogs-divider" />
      </div>
      <div className='blogsWrapper'>
        <div className='blogs'>
          {loading ? (
            // Render skeleton loaders when loading
            Array.from({ length: blogPostsData.length || 3 }).map((_, index) => (
              <BlogCard key={index} loading={true} isFeatured={index === 0} />
            ))
          ) : (
            filteredBlogPosts.map((post, index) => (
              <BlogCard
                key={post.id}
                image={post.image}
                imageAlt={post.imageAlt}
                isFeatured={index === 0}
                categories={post.categories}
                description={post.metaDesc}
                {...post}
              />
            ))
          )}
        </div>
      </div>
    </main>
  );
}

