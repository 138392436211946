import React from 'react';
import './Icon.css';

const PlusIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        <g className="icon-graphics plus" transform="scale(0.6) translate(1850, 1800)" stroke="white" strokeWidth="120" fill="#022852">
            <path d="M1400 2984 c-111 -29 -207 -118 -250 -231 -22 -57 -22 -62 -16 -470 l6 -412 -433 -3 -432 -3 -67 -33 c-274 -134 -273 -530 0 -664 l67 -33 432 2 432 2 -2 -432 -2 -432 33 -67 c111 -227 414 -276 595 -96 27 27 60 74 75 106 l27 57 3 432 3 433 427 -3 427 -2 57 27 c69 32 145 106 176 170 91 188 18 410 -166 500 l-67 33 -425 5 -425 5 -5 425 -5 425 -26 55 c-49 105 -134 176 -244 205 -72 18 -123 18 -195 -1z"/>
        </g>
      </svg>
    </div>
  );
};

export default PlusIcon;
