import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
// Global axios defaults
axios.defaults.withCredentials = true;

// Creating an axios instance with default settings
const instance = axios.create({
    baseURL: API_URL,
    withCredentials: true // Ensure credentials are sent with each request
});

export default instance;
