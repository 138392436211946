import React from 'react';
import './Icon.css';

const BlogIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(1100,3190) scale(0.4,-0.4)" strokeWidth="155" fill="none">
            <path xmlns="http://www.w3.org/2000/svg" d="M645 4513 c-286 -40 -528 -248 -617 -533 l-23 -75 0 -880 c0 -821 1 -884 18 -945 60 -211 201 -384 392 -480 156 -78 106 -74 985 -78 l785 -2 435 -434 c239 -239 449 -441 467 -450 68 -34 158 -4 194 64 18 32 19 64 19 426 l0 392 293 5 c308 4 340 9 453 58 165 73 320 227 393 393 57 131 55 90 58 996 3 563 0 858 -7 904 -27 177 -100 316 -231 440 -113 108 -239 171 -391 196 -60 9 -3153 13 -3223 3z m3197 -304 c172 -37 315 -183 348 -358 14 -77 14 -1603 -1 -1673 -29 -138 -114 -245 -246 -310 l-86 -43 -381 -5 c-513 -7 -475 26 -476 -400 l0 -285 -332 332 c-183 183 -346 337 -361 342 -19 8 -288 11 -821 11 -878 0 -857 -1 -967 66 -62 37 -126 103 -165 169 -53 91 -54 104 -54 970 0 754 1 804 19 868 41 150 171 274 326 313 69 17 3117 20 3197 3z"/>
        </g>
      </svg>
    </div>
  );
};

export default BlogIcon;
