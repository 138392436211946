import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from './../../api/axios'; // Ensure axios is imported
import '../styles/BlogsAdmin.css';
import AdminHeader from "./AdminHeader";

export default function BlogsAdmin() {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            // Update to use axios and include credentials
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-blogs`);
            setBlogs(response.data);
        } catch (error) {
            console.error("Failed to fetch blogs:", error);
        }
    };

    const stripHtml = (html) => {
        const temporalDivElement = document.createElement("div");
        temporalDivElement.innerHTML = html;
        return temporalDivElement.textContent || temporalDivElement.innerText || "";
    };

    const deleteBlog = async (title) => {
        try {
            // Update to use axios and include credentials
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-blog/${title}`);
            setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.title !== title));
        } catch (error) {
            console.error("Failed to delete blog:", error);
        }
    };

    //TODO TEST CKEDITOR AS THE LOAN FORM FOR BLOG CREATION

    return (
        <div>
            <AdminHeader />
            <div className="formButtons">
                <Link to="/admin/blogs/new-blog"><button className="add-blog-btn">Add Blog</button></Link>
            </div>
            
            <div className="blogs-list">
                {blogs.map(blog => (
                    <div key={blog.id} className="blog-entry">
                        <div className="blog-date">
                          {new Date(blog.published || blog.date).toLocaleDateString('en-US', {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </div>
                        <h3>{blog.title}</h3>
                        {<img 
                            src={`${process.env.REACT_APP_API_URL}/uploads/blogs/${blog.image}`}
                            alt={blog.imageAlt || "Blog image"}
                            className="blog-image"
                            />
                        }
                        <p>{stripHtml(blog.content).substring(0, 100)}...</p>
                        <Link to={`/admin/blogs/edit-blog/${blog.title}`}><button className="edit-btn">Edit</button></Link>
                        <button onClick={() => deleteBlog(blog.title)} className="delete-btn">Delete</button>
                    </div>
                ))}
            </div>
        </div>
    );
}
