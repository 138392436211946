import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './styles/NavBar.css';
import Logo from './../assets/icons/Logo';
import { useAuth } from './../context/AuthProvider';

const NavBar = () => {
  const { auth } = useAuth();
  const location = useLocation();

  const [logoHovered, setLogoHovered] = useState(false);
  const [logoMouseDown, setLogoMouseDown] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [activeLink, setActiveLink] = useState(null);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = () => setIsMenuVisible(prev => !prev);

  useEffect(() => {
    setIsMenuVisible(false);
  }, [auth]);

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeFixed = window.scrollY > 400;
      const isMobile = window.innerWidth <= 775;
      setIsFixed(isMobile && shouldBeFixed);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location]);
  const [key] = useState(0);
  const pathsToShowLineAndPlug = ['/', '/about', '/services', '/blog'];
  const shouldShowLineAndPlug = pathsToShowLineAndPlug.includes(location.pathname);

  return (
    <>
      {isFixed && <div className="navbar-placeholder"></div>}
      <nav className={`navbar ${isFixed ? 'navbar-fixed' : ''}`} aria-label="Main navigation">
        <div className='navBarWrapper'>
          <a className="skip-to-content-link" href="#main-content">Skip to content</a>
          <div className='smallScreenWrapper'>
            <div className="logo-svg"
              onMouseEnter={() => setLogoHovered(true)}
              onMouseLeave={() => setLogoHovered(false)}
              onMouseDown={() => setLogoMouseDown(true)}
              onMouseUp={() => setLogoMouseDown(false)}>
              <Link to="/">
                <Logo
                  scaleMultiplier={1}
                  bulbColor={logoHovered ? "#e4a906" : "#022852"}
                  networkColor={logoHovered ? "#e4a906" : "#022852"}
                  lumensTransform={
                    logoMouseDown ? `scale(3.1) translate(-1rem, -0.8rem)` : 
                    (logoHovered ? "scale(3.6) translate(-1.7rem, -1.5rem)" : "scale(3.2) translate(-1.15rem, -0.9rem)")
                  }
                  wwwColor={"#e4a906"}
                  shouldAnimate={true}
                />
              </Link>
            </div>

            <div className="hamburger-toggle" aria-label="Toggle menu">
              {isMenuVisible ? <i className="fa-solid fa-xmark" onClick={toggleMenu}></i> : <i className="fa-solid fa-bars" onClick={toggleMenu}></i>}
            </div>
          </div>

          <div className={`navLinkWrapper ${isMenuVisible ? 'show' : 'hide'}`}>
            <div className='navLinks'>
              <NavLink 
                to="/" 
                activeclassname="active" 
                onClick={() => isMenuVisible && toggleMenu()}
                onMouseDown={() => setActiveLink('/')}
                onMouseUp={() => setActiveLink(null)}
                className={activeLink === '/' ? 'active-mousedown' : ''}
              >Home</NavLink>
              <NavLink 
                to="/about" 
                activeclassname="active" 
                onClick={() => isMenuVisible && toggleMenu()}
                onMouseDown={() => setActiveLink('/about')}
                onMouseUp={() => setActiveLink(null)}
                className={activeLink === '/about' ? 'active-mousedown' : ''}
              >About Us</NavLink>
              <NavLink 
                to="/services" 
                activeclassname="active" 
                onClick={() => isMenuVisible && toggleMenu()}
                onMouseDown={() => setActiveLink('/services')}
                onMouseUp={() => setActiveLink(null)}
                className={activeLink === '/services' ? 'active-mousedown' : ''}
              >Our Services</NavLink>
              <HashLink 
                smooth 
                to="/about#contact" 
                onClick={() => isMenuVisible && toggleMenu()}
                onMouseDown={() => setActiveLink('/about#contact')}
                onMouseUp={() => setActiveLink(null)}
                className={activeLink === '/about#contact' ? 'active-mousedown' : ''}
              >Contact Us</HashLink>
              {(auth.isAuthenticated) && (
                <NavLink 
                  to="/admin/dashboard" 
                  activeclassname="active" 
                  onClick={() => isMenuVisible && toggleMenu()}
                  onMouseDown={() => setActiveLink('/admin/dashboard')}
                  onMouseUp={() => setActiveLink(null)}
                  className={activeLink === '/admin/dashboard' ? 'active-mousedown' : ''}
                >Admin</NavLink>
              )}
            </div>
            <div className='navLinkBlog'>
              <NavLink 
                to="/blog" 
                activeclassname="active" 
                onClick={() => isMenuVisible && toggleMenu()}
                onMouseDown={() => setActiveLink('/blog')}
                onMouseUp={() => setActiveLink(null)}
                className={activeLink === '/blog' ? 'active-mousedown' : ''}
              ><i className="fa-regular fa-comments"></i>Blog</NavLink>
            </div>
          </div>

          {shouldShowLineAndPlug && (
            <div key={key} className="navbar-yellow-line">
              <i className="fa-solid fa-plug"></i>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default NavBar;
