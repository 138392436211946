import React, { useEffect, useState, useRef } from 'react';
import './styles/Home.css';
import HeroCarousel from '../components/HeroCarousel'; // Import HeroCarousel
import Services from '../components/Services';
import Benefits from '../components/Benefits';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';

import Logo from '../assets/icons/Logo';

export default function Home() {
    const [slides, setSlides] = useState([]);
    const aboutSectionRef = useRef(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSlides = async () => {
            setLoading(true); // Ensure loading is set to true at the start of fetch
            try {
                const response = await axios.get('/api/get-slides');
                const slidesData = response.data.map(slide => ({
                    ...slide,
                    image: slide.image ? `${process.env.REACT_APP_API_URL}/uploads/slides/${slide.image}` : null,
                }));
    
                // Add a delay for testing purposes
                setTimeout(() => {
                    setSlides(slidesData);
                    setLoading(false); // Set loading to false after data is fetched
                }, 30); // 0.13s delay
            } catch (error) {
                console.error("Failed to fetch slides:", error);
                setLoading(false); // Set loading to false in case of error
            }
        };
        fetchSlides();
    }, []);
    
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-up');
                    // Check if the observed entry is the "About" section
                    if (entry.target.classList.contains('about')) {
                        const image = entry.target.querySelector('.about-image');
                        if (image) {
                            // Add a delay before enlarging the image
                            setTimeout(() => {
                                image.classList.add('scale-up');
                            }, 380); // Adjust the delay as needed
                        }
                    }
                } else {
                    // Optional: Reset the effect when the section is not in view
                    const image = entry.target.querySelector('.about-image');
                    if (image) {
                        image.classList.remove('scale-up');
                    }
                }
            });
        }, { threshold: 0.12 });

        document.querySelectorAll('section').forEach(section => {
            if (!section.classList.contains('hero') && !section.classList.contains('benefits')) {
                observer.observe(section);
            }
        });

        return () => observer.disconnect();
    }, []);


    const scrollToAboutSection = () => {
        if (aboutSectionRef.current) {
            const offset = 85; // Adjust this value to add an offset if needed
            const top = aboutSectionRef.current.getBoundingClientRect().top + window.scrollY - offset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    };

    return (
        <div>
            <Helmet>
                <title>Home - WattsWebWorks West Virginia Web Design</title>
                <meta name="description" content="WattsWebWorks offers premier web design and digital services tailored for businesses in West Virginia, ensuring a strong online presence." />
                <meta property="og:title" content="Home - WattsWebWorks West Virginia Web Design" />
                <meta property="og:description" content="WattsWebWorks offers premier web design and digital services tailored for businesses in West Virginia, ensuring a strong online presence." />
                <meta property="og:url" content="https://wattswebworks.com" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebSite",
                        "name": "WattsWebWorks",
                        "url": "https://wattswebworks.com",
                        "description": "WattsWebWorks specializes in creating dynamic, user-friendly websites and web applications to power your online presence.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "WattsWebWorks",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://wattswebworks.com512.png"
                            }
                        }
                    })}
                </script>
            </Helmet>

            <main className="section-wrapper">

                <section className="hero persistent" aria-labelledby="hero-section">
                    <HeroCarousel slides={slides} loading={loading}/> 
                </section>

                <div className='section-separator'>
                    <div className="about-separator-step-1"></div>
                    <div className="about-separator-step-2"></div>
                    <div className="about-separator-step-3" onClick={scrollToAboutSection}>
                        <p><i className="fa-solid fa-angles-down"/><br/><span>Learn More</span></p>
                    </div>
                    <div className="about-separator-step-4"></div>
                    <div className="about-separator-step-5"></div>
                </div>

                <section className="about" aria-labelledby="about-us" ref={aboutSectionRef}>
                    <div className="about-container">
                        <div className='about-logo'>
                            <Logo
                                scaleMultiplier={4}
                                shouldAnimate={false}
                                lumensTransform={'scale(12.8) translate(-1.15rem, -0.9rem)'}
                                hideLumens={false}
                                bulbColor={"#022852"}
                                networkColor={"#022852"}
                                cycleColors={true}
                                cycleInterval={1250}
                            />
                        </div>
                        <div className="about-content">
                            <h2>Why Choose Us?</h2>
                            <p>
                                At WattsWebWorks, our commitment to <span className="emphasis">illuminating</span> your online presence stands at the forefront of our services. 
                                Leveraging a blend of innovative design and cutting-edge technology, we craft websites that not only resonate with your audience but also help 
                                empower your business. Our passion for distinctive and impactful digital experiences drives us to achieve excellence in every project, 
                                meaning yours will be handled with precision and creativity, and will not only meet but exceed 
                                expectations. By choosing WattsWebWorks, you're opting for a partner who values your success as much as you do, providing a foundation for growth, visibility, and success. 
                            </p>
                        </div>

                    </div>
                </section>

                <div className='section-separator'>
                    <div className="benefits-separator-step-1"></div>
                    <div className="benefits-separator-step-2"></div>
                    <div className="benefits-separator-step-3"></div>
                    <div className="benefits-separator-step-4"></div>
                </div>


                <section className="benefits" aria-label='Our Comprehensive Approach to Optimizing Your Web Potential'>
                    <Benefits />
                </section>

                <div className='section-separator services-separator'>
                    <div className="services-separator-step-1"></div>
                    <div className="services-separator-step-2"></div>
                    <div className="services-separator-step-3"></div>
                    <div className="services-separator-step-4"></div>
                </div>

                <section className="services" aria-label='Our Services'>
                    <div className="overlay"></div>
                        <Services />
                </section>



            </main>


        </div>
    );
}
