import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from '../../api/axios';
import AdminSlide from './AdminSlide'; // Ensure this is the correct import path
import SlidePreview from './SlidePreview';
import './../styles/SlidesAdmin.css'
import AdminHeader from "./AdminHeader";

function SlidesAdmin() {
  const [slides, setSlides] = useState([]);
  const [selectedSlideId, setSelectedSlideId] = useState(null);

  useEffect(() => {
    fetchSlides();
  }, []);

  const fetchSlides = async () => {
    try {
      const response = await axios.get('/api/get-slides', { withCredentials: true });
      setSlides(response.data);
      // Optionally set the first slide as selected by default
      if (response.data && response.data.length > 0) {
        setSelectedSlideId(response.data[0].id);
      }
    } catch (error) {
      console.error("Failed to fetch slides:", error);
    }
  };

  const deleteSlide = async (id) => {
    try {
        await axios.delete(`/api/delete-slide/${id}`, { withCredentials: true });
        // Remove the slide from state and reset selectedSlideId if it was deleted
        setSlides(prevSlides => prevSlides.filter(slide => slide.id !== id));
        if (id === selectedSlideId) {
            setSelectedSlideId(null);
        }
    } catch (error) {
        console.error("Failed to delete slide:", error);
    }
  };

  const handlePreviewSelect = (id) => {
    setSelectedSlideId(id);
  };

  return (
    <div className="slides-admin">
      <AdminHeader />
      <div className="formButtons">
        <Link to="/admin/slides/new-slide">
          <button className="add-slide-btn">Add New Slide</button>
        </Link>
      </div>
      <div className="slide-previews">
        {slides.map((slide, index) => (
          <div key={slide.id}>
            <div className="slide-number">Slide {index + 1}</div> {/* Displaying slide number */}
            <SlidePreview
              slide={slide}
              onPreviewSelect={handlePreviewSelect}
              isSelected={slide.id === selectedSlideId} // Pass isSelected as true if this slide is selected
            />
          </div>
        ))}
      </div>
      {slides.filter(slide => slide.id === selectedSlideId).map(slide => (
        <AdminSlide key={slide.id} slide={slide} onDelete={deleteSlide} />
      ))}
    </div>
  );
}

export default SlidesAdmin;
