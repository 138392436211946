import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import axios from '../../api/axios';
import './../styles/AddSlide.css'; // Ensure the path is correct

function AddSlide() {
  const [caption, setCaption] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [buttonLink, setButtonLink] = useState('');
  const [slideAlign, setSlideAlign] = useState('left');
  const [image, setImage] = useState(null);
  const [slideType, setSlideType] = useState('image');
  const [slideColor, setSlideColor] = useState('none');
  const [slideGradient, setSlideGradient] = useState('none');
  const [slideBaseColor, setSlideBaseColor] = useState('');

  const [buttonColor, setButtonColor] = useState('');
  const [buttonTextColor, setButtonTextColor] = useState('');
  const [buttonGlowColor, setButtonGlowColor] = useState('');
  const [buttonAdditionalStyling, setButtonAdditionalStyling] = useState('');

  const [slideContent, setSlideContent] = useState(false);
  const [slideButton, setSlideButton] = useState(false);
  const [foregroundStyling, setForegroundStyling] = useState('');
  const [showForegroundStyling, setShowForegroundStyling] = useState(false);
  const [showButtonGlow, setShowButtonGlow] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const editMode = id ? true : false;

  useEffect(() => {
    if (id) {
      axios.get(`/api/get-slide/${id}`, { withCredentials: true })
        .then(response => {
          const {
            caption, description, buttonText, buttonLink, slideAlign,
            slideType, color, gradient, baseColor, content, button, 
            foregroundStyling, buttonColor, buttonTextColor, buttonGlowColor, buttonAdditionalStyling
          } = response.data;

          setCaption(caption);
          setDescription(description);
          setButtonText(buttonText);
          setButtonLink(buttonLink);
          setSlideAlign(slideAlign);
          setSlideType(slideType || 'image');
          setSlideColor(color || '');
          setSlideGradient(gradient || '');
          setSlideBaseColor(baseColor || '');
          setSlideContent(content === 1);
          setSlideButton(button === 1);
          setForegroundStyling(foregroundStyling || '');
          setShowForegroundStyling(!!foregroundStyling);
          setButtonColor(buttonColor || '');
          setButtonTextColor(buttonTextColor || '');
          setButtonGlowColor(buttonGlowColor || '');
          setShowButtonGlow(buttonGlowColor && buttonGlowColor !== 'none');
          setButtonAdditionalStyling(buttonAdditionalStyling || '');
        })
        .catch(error => console.error('Error fetching slide:', error));
    }
  }, [id]);

  const handleImageChange = e => setImage(e.target.files[0]);

  const handleSubmit = async e => {
    e.preventDefault();


    const finalForegroundStyling = showForegroundStyling ? (foregroundStyling || 'none') : 'none';
    const finalButtonAdditionalStyling = buttonAdditionalStyling || 'none';


    const formData = new FormData();
    formData.append('content', slideContent ? 1 : 0);
    formData.append('button', slideButton ? 1 : 0);
    formData.append('caption', caption);
    formData.append('description', description);
    formData.append('buttonText', buttonText);
    formData.append('buttonLink', buttonLink);
    formData.append('slideAlign', slideAlign);
    formData.append('slideType', slideType);
    formData.append('color', slideColor);
    formData.append('gradient', slideGradient);
    formData.append('baseColor', slideBaseColor);
    formData.append('foregroundStyling', finalForegroundStyling);
    formData.append('buttonColor', buttonColor);
    formData.append('buttonTextColor', buttonTextColor);
    formData.append('buttonGlowColor', showButtonGlow ? buttonGlowColor : 'none');
    formData.append('buttonAdditionalStyling', finalButtonAdditionalStyling);
    if (image) formData.append('image', image); // Only append image if it's set
    try {
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      };
      const url = editMode ? `/api/update-slide/${id}` : '/api/add-slide';
      const method = editMode ? axios.put : axios.post;
      await method(url, formData, config);
      navigate('/admin/slides');
    } catch (error) {
      console.error('Error submitting slide:', error);
    }
  };

  const handleTypeChange = (e) => {
    const type = e.target.value;
    setSlideType(type);
  
    if (type === 'image') {
      setSlideColor('');
      setSlideGradient('');
    } else if (type === 'solid-color') {
      setImage(null);
      setSlideGradient('');
    } else if (type === 'gradient') {
      setImage(null);
      setSlideColor('');
    }
  };

  const handleSlideContentChange = (e) => {
    const isChecked = e.target.checked;
    setSlideContent(isChecked);
    if (!isChecked) {
      setSlideButton(false);
    }
  };

  const handleForegroundStylingChange = (e) => {
    setShowForegroundStyling(e.target.checked);
    if (!e.target.checked) {
      setForegroundStyling('');
    }
  };

  const handleButtonGlowChange = (e) => {
    setShowButtonGlow(e.target.checked);
    if (!e.target.checked) {
      setButtonGlowColor('none');
    }
  };

  return (
    <>
      <div className="formButtons">
        <Link to="/admin/slides"><button className="cancelEdit">Cancel</button></Link>
      </div>
      <div className="add-slide-container">
        <div className="add-slide-form">
          <h2>{id ? "Edit Slide" : "Add New Slide"}</h2>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Slide Content:</label>
              <input
                type="checkbox"
                checked={slideContent}
                onChange={handleSlideContentChange}
              />
              {slideContent && (
                <>
                  <label>Slide Button:</label>
                  <input
                    type="checkbox"
                    checked={slideButton}
                    onChange={(e) => setSlideButton(e.target.checked)}
                  />
                </>
              )}
            </div>
            {slideContent && (
              <>
                <div>
                  <label>Caption:</label>
                  <input type="text" value={caption} onChange={(e) => setCaption(e.target.value)} maxLength={75} required />
                </div>
                <div>
                  <label>Description:</label>
                  <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} maxLength={255} required />
                </div>
              </>
            )}
            {slideButton && (
              <>
                <div>
                  <label>Button Text:</label>
                  <input type="text" value={buttonText} onChange={(e) => setButtonText(e.target.value)} maxLength={45} required />
                </div>
                <div>
                  <label>Button Link:</label>
                  <input type="text" value={buttonLink} onChange={(e) => setButtonLink(e.target.value)} maxLength={45} required />
                </div>
                <div>
                    <label>Button Color:</label>
                    <input type="color" value={buttonColor} onChange={(e) => setButtonColor(e.target.value)} maxLength={45}/>
                </div>
                <div>
                    <label>Button Text Color:</label>
                    <input type="color" value={buttonTextColor} onChange={(e) => setButtonTextColor(e.target.value)} maxLength={45}/>
                </div>
                <div>
                    <label>Button Glow:</label>
                    <input
                      type="checkbox"
                      checked={showButtonGlow}
                      onChange={handleButtonGlowChange}
                    />
                </div>
                {showButtonGlow && (
                  <div>
                      <label>Glow Color:</label>
                      <input type="color" value={buttonGlowColor} onChange={(e) => setButtonGlowColor(e.target.value)} maxLength={45}/>
                  </div>
                )}
                <div>
                    <label>Button Additional Styling:</label>
                    <input type="text" value={buttonAdditionalStyling} onChange={(e) => setButtonAdditionalStyling(e.target.value)} maxLength={150}/>
                </div>
              </>
            )}
            <div>
              <label>Slide Content Align:</label>
              <select value={slideAlign} onChange={(e) => setSlideAlign(e.target.value)} required>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
              </select>
            </div>
            <div>
              <label>Slide Type:</label>
              <select value={slideType} onChange={handleTypeChange} required>
                  <option value="image">Image</option>
                  <option value="gradient">Gradient</option>
                  <option value="solid-color">Solid Color</option>
              </select>
            </div>

            {slideType === 'image' && (
                <div>
                    <label>Image:</label>
                    <input type="file" onChange={handleImageChange} accept="image/*" />
                </div>
            )}
            {slideType === 'solid-color' && (
                <div>
                    <label>Color:</label>
                    <input type="color" value={slideColor} onChange={(e) => setSlideColor(e.target.value)} maxLength={45}/>
                </div>
            )}
            {slideType === 'gradient' && (
                <div>
                    <label>Gradient:</label>
                    <input type="text" value={slideGradient} onChange={(e) => setSlideGradient(e.target.value)} maxLength={45} placeholder="linear-gradient(to right, red, yellow)"/>
                </div>
            )}
            <div>
                <label>Additional Foreground Styling:</label>
                <input
                  type="checkbox"
                  checked={showForegroundStyling}
                  onChange={handleForegroundStylingChange}
                />
            </div>
            {showForegroundStyling && (
              <>
              <div>
                  <label>Foreground Styling:</label>
                  <input type="text" value={foregroundStyling} onChange={(e) => setForegroundStyling(e.target.value)} maxLength={150}/>
              </div>
              <div>
                  <label>Slide Base Color:</label>
                  <input type="color" value={slideBaseColor} onChange={(e) => setSlideBaseColor(e.target.value)} maxLength={45}/>
              </div>
            </>
            )}

            <button type="submit">{editMode ? 'Update' : 'Add'} Slide</button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddSlide;
