import React from 'react';
import './Icon.css';

const DevelopmentIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(1150,2850) scale(0.18,-0.18)" strokeWidth="340" fill="none">
            <path xmlns="http://www.w3.org/2000/svg" d="M1505 7934 c-69 -15 -115 -29 -153 -47 -56 -25 -153 -115 -177 -164 -11 -21 -25 -45 -31 -53 -7 -8 -14 -31 -17 -50 -3 -19 -11 -61 -17 -93 -7 -40 -10 -263 -8 -680 3 -588 4 -624 22 -649 l18 -28 128 0 c71 0 135 3 144 6 14 6 16 73 16 660 0 727 -2 701 66 748 l39 26 3507 0 c2302 0 3516 -3 3533 -10 14 -5 39 -24 56 -42 l29 -32 0 -2161 c0 -1188 -4 -2166 -8 -2172 -6 -10 -537 -13 -2523 -15 l-2514 -3 -36 -24 c-19 -14 -45 -41 -57 -62 -21 -37 -22 -47 -22 -399 0 -334 1 -364 19 -398 26 -50 49 -69 107 -87 43 -13 351 -15 2475 -15 1733 0 2450 3 2509 11 49 7 103 22 133 36 91 46 217 192 217 252 0 21 23 44 33 34 4 -3 7 1137 7 2535 0 2238 -2 2542 -15 2542 -8 0 -17 12 -20 28 -9 37 -31 78 -78 140 -52 69 -108 107 -227 153 -29 12 -602 14 -3585 15 -1952 1 -3559 0 -3570 -2z m3655 -5004 c60 -48 80 -87 80 -160 0 -63 -21 -116 -60 -148 -56 -46 -136 -64 -193 -43 -38 15 -96 70 -118 112 -41 80 -11 187 67 241 34 24 49 27 113 28 67 0 76 -3 111 -30z"/>
            <path xmlns="http://www.w3.org/2000/svg" d="M210 5762 c-102 -32 -140 -61 -185 -145 l-25 -48 0 -2158 c0 -1843 2 -2163 14 -2192 24 -56 83 -116 144 -146 l57 -28 1304 -3 c1217 -2 1308 -1 1363 15 69 21 144 81 179 145 l24 43 0 2160 0 2160 -24 48 c-36 74 -105 128 -193 149 -68 16 -161 18 -1338 17 -1155 -1 -1270 -2 -1320 -17z m1685 -306 c21 -32 7 -60 -36 -75 -45 -16 -609 -15 -644 1 -30 13 -41 48 -25 77 11 21 16 21 350 21 l340 0 15 -24z m925 -267 l25 -21 3 -1722 c2 -946 0 -1740 -3 -1763 -11 -80 110 -73 -1305 -73 l-1269 0 -20 26 -21 27 0 1743 0 1742 26 31 26 31 1256 0 1257 -1 25 -20z m-1215 -3669 c27 -11 59 -48 75 -88 17 -43 -1 -101 -46 -149 -29 -31 -36 -33 -96 -33 -61 0 -67 2 -100 35 -38 38 -52 84 -41 139 18 89 112 133 208 96z"/>
            <path xmlns="http://www.w3.org/2000/svg" d="M4385 1825 c-24 -23 -25 -30 -25 -150 0 -138 -12 -180 -53 -190 -12 -3 -192 -6 -400 -7 l-379 -3 -20 -24 c-18 -23 -19 -38 -16 -184 3 -155 3 -159 28 -177 24 -19 64 -20 1706 -20 1640 0 1682 0 1705 19 23 18 24 26 27 152 2 73 1 152 -3 176 -6 38 -10 43 -43 53 -23 6 -222 10 -525 10 l-488 0 -24 25 c-24 23 -25 29 -25 160 0 131 -1 137 -25 160 l-24 25 -696 0 -696 0 -24 -25z"/>   
        </g>
      </svg>
    </div>
  );
};

export default DevelopmentIcon;
