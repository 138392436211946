import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import './styles/Login.css';

function Login() {
  const { auth, login } = useAuth();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/admin/dashboard');
    }
  }, [auth, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await login({ username, password });
      if (response && response.user) {
        setLoginStatus('Login successful');
      } else {
        setLoginStatus(response.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginStatus('Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="loginWrapper unique-login-class">
      <div className="login-container">
        <h1>Login</h1>
        <form onSubmit={handleLogin} className="form">
          <div className="input-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              required
              aria-label="Username"
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              required
              aria-label="Password"
            />
          </div>
          <button type="submit" className="login-btn" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        {loginStatus && <p className="errmsg" aria-live="polite">{loginStatus}</p>}
      </div>
    </div>
  );
}

export default Login;
