import React from "react";
import './../styles/SlidePreview.css'; // Ensure you have a CSS file for SlidePreview styles

const SlidePreview = ({ slide, onPreviewSelect, isSelected }) => {
  const previewClass = isSelected ? "slide-preview selected" : "slide-preview";

  // Determine background style based on slide type
  const getBackgroundStyle = (slide) => {
    if (slide.slideType === 'solid-color') {
      return { backgroundColor: slide.color };
    } else if (slide.slideType === 'gradient') {
      return { background: slide.gradient };
    }
    return {};
  };

  // Function to scale the style values
  const scaleStyleValue = (value, scale) => {
    const number = parseFloat(value);
    const unit = value.replace(number, '');
    return (number / scale) + unit;
  };

  // Function to parse CSS string to object with camelCase properties and scaled values
  const parseAndScaleForegroundStyling = (stylingString, scale = 1) => {
    if (!stylingString || stylingString === 'none') return {};

    const toCamelCase = (str) => {
      return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    };

    return stylingString.split(';').reduce((styles, style) => {
      const [property, value] = style.split(':').map(str => str.trim());
      if (property && value) {
        const camelCaseProperty = toCamelCase(property);
        styles[camelCaseProperty] = scale === 1 ? value : scaleStyleValue(value, scale);
      }
      return styles;
    }, {});
  };

  // Adjust the scale as needed (e.g., 6 for 6x smaller)
  const scale = 4.2;

  return (
    <div className="slide-preview-wrapper" onClick={() => onPreviewSelect(slide.id)}>
      <div className={previewClass}>
        <div className="slide-preview-background" style={{ backgroundColor: slide.baseColor }}>
          <div
            className="slide-foreground"
            style={{
              ...getBackgroundStyle(slide),
              ...parseAndScaleForegroundStyling(slide.foregroundStyling, scale),
            }}
          >
            {slide.slideType === 'image' && (
              <img
                src={`${process.env.REACT_APP_API_URL}/uploads/slides/${slide.image}`}
                alt="Preview"
                className="slide-preview-image"
              />
            )}
            {(slide.slideType === 'solid-color' || slide.slideType === 'gradient') && (
              <div className="slide-color-background" style={getBackgroundStyle(slide)}></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlidePreview;
