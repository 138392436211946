import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import './styles/Footer.css';
import FooterMap from './FooterMap';

const Footer = () => {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <footer className="footer" aria-labelledby="footer-heading">
      <div className="footer-cta">
        <div className="footer-cta-text">
          <h2 id="footer-heading">Looking to power up your online presence and streamline your digital strategy?</h2>
          <p>Contact us to discover how we can <span>illuminate</span> your path to digital success!</p>
        </div>
        <div className="footer-cta-button">
          <HashLink to="/about#contact" className='footer-button' aria-label="Get in Touch">Get in Touch</HashLink>
        </div>
      </div>

      <hr className="footer-divider" />

      <div className="footer-content">
        <div className="footer-column footer-contact">
          <h3 className="footer-heading">Location</h3>
          <p className="footer-phone">304-936-2254</p>
          <p className="footer-phone">contact@wattswebworks.com</p>
          <address>
            West Virginia
            <FooterMap/>
          </address>
        </div>

        <div className="footer-center-text">
          <p>Copyright <span>©</span> 2024 WattsWebWorks LLC</p>
          <p onClick={scrollToTop} className="back-to-top-button" aria-label="Back to Top"><i className="fa-solid fa-arrows-up-to-line"/>&nbsp;<span>TO TOP</span>&nbsp;<i className="fa-solid fa-arrows-up-to-line"/></p>
        </div>

        <div className="footer-column footer-nav">
          <h3 className="footer-heading">Navigation</h3>
          <ul>
            <li><Link to="/about" onClick={scrollToTop}>Who We Are</Link></li>
            <li><Link to="/services" onClick={scrollToTop}>Our Services</Link></li>
            <li><HashLink to="/about#contact" aria-label="Contact Us">Contact Us</HashLink></li>
            <li><Link to="/blog" onClick={scrollToTop}>Our Blog</Link></li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
