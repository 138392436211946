  import React, {useEffect} from 'react';
  import './App.css';
  import { Routes, Route, useLocation } from 'react-router-dom';
  import NavBar from './components/NavBar';
  import ContactBar from './components/ContactBar';
  import PrivateRoute from './components/PrivateRoute';

  import Home from './pages/Home';
  import About from './pages/About';
  import Services from './pages/Services';
  import Footer from './components/Footer';
  import Blog from './pages/Blog';
  import BlogView from './pages/BlogView';

  import Admin from './pages/Admin';
  import AddBlog from './components/admin/AddBlog';
  import AddUser from './components/admin/AddUser';
  import Login from './components/Login'; // Adjust the path as necessary
  import AddSlide from './components/admin/AddSlide';
  import BlogCategoryView from './components/BlogCategoryView';
  import BlogTagView from './components/BlogTagView';
  import UsersAdmin from './components/admin/UsersAdmin';
  import BlogsAdmin from './components/admin/BlogsAdmin';
  import SlidesAdmin from './components/admin/SlidesAdmin';
  import NotFoundPage from './pages/NotFoundPage';

  import webDesignImage from './assets/images/webDesignNew.png';
  import missionImage from './assets/images/multipleWebsites.png';

  import countryRoadsImage from './assets/images/countryRoads.jpg';
  import servicesBG from './assets/images/servicesBG.jpg';
  import InquiriesAdmin from './components/admin/InquiriesAdmin';
  import AdminDashboard from './components/admin/AdminDashboard';

  function App() {
    // Use useLocation to get the current path
    const location = useLocation();
    
    // Determine if the footer should be shown based on the current path
    const showFooter = ['/', '/about', '/services'].includes(location.pathname) || location.pathname.startsWith('/blog');

    useEffect(() => {
      const imagesToPreload = [
        countryRoadsImage,
        servicesBG,
        webDesignImage,
        missionImage
      ];
      imagesToPreload.forEach(imageSrc => {
        const img = new Image();
        img.src = imageSrc;
      });
    }, []);

    return (
      <div className="App">
        <header role="banner">
          <ContactBar />
          <NavBar />
        </header>

        <main id="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/category/:category" element={<BlogCategoryView />} />
            <Route path="/blog/tag/:tag" element={<BlogTagView />} />
            <Route path="/blog/:title" element={<BlogView />} />

            <Route path="/login" element={<Login />} />
            
            
            <Route element={<PrivateRoute />}>
              <Route path="/admin" element={<Admin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="slides" element={<SlidesAdmin />} />
                <Route path="slides/new-slide" element={<AddSlide />} />
                <Route path="slides/edit-slide/:id" element={<AddSlide />} />
                <Route path="blogs" element={<BlogsAdmin />} />
                <Route path="blogs/new-blog" element={<AddBlog />} />
                <Route path="blogs/edit-blog/:title" element={<AddBlog />} />
                <Route path="inquiries" element={<InquiriesAdmin />} />
                <Route path="users" element={<UsersAdmin />} />
                <Route path="users/new-user" element={<AddUser />} />
                <Route path="users/edit-user/:id" element={<AddUser />} />
              </Route>
            </Route>


            {/* Catch-all route for handling 404 Not Found pages */}
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </main>
        {showFooter && <Footer />}
      </div>
    );
  }

  export default App;
