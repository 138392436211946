import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/Benefits.css';

import PlannerImage from '../assets/images/Planner.png';
import AnalyzeImage from '../assets/images/Analyze.png';
import ImplementImage from '../assets/images/Implement.png';

const Benefits = () => {
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-up');
                } else {
                    entry.target.classList.remove('slide-up');
                }
            });
        }, { threshold: 0.12 });

        const items = document.querySelectorAll('.benefit-item');
        items.forEach(item => {
            try {
                observer.observe(item);
            } catch (error) {
                console.error('Error observing item:', error);
            }
        });

        return () => observer.disconnect();
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className='benefits-content'>
            <div>
                <h2 className='benefit-item'>Optimizing Your Web Potential: Our <span className='emphasis'>Comprehensive</span> Approach</h2>
                <p className="h4 accent margin-top-20 benefit-item">Unlock the full potential of your web presence with our tailored development solutions.</p>
            </div>
            <div className="row">
                <div className="step benefit-item">
                    <img src={AnalyzeImage} width="128" height="128" alt="Analyzing web data" />
                    <h4>Analyze</h4>
                    <p>Start with a comprehensive review and analysis of your existing website and online presence to identify key areas for enhancement and growth.</p>
                </div>
                <div className="step benefit-item">
                    <img src={PlannerImage} width="128" height="128" alt="Planning web strategy" />
                    <h4>Plan</h4>
                    <p>Develop a detailed roadmap that guides the web development process, ensuring it aligns seamlessly with your business objectives and digital aspirations.</p>
                </div>
                <div className="step benefit-item">
                    <img src={ImplementImage} width="128" height="128" alt="Implementing web solutions" />
                    <h4>Implement</h4>
                    <p>Transform strategy into reality with a focus on agile development, timely delivery, and meticulous attention to detail, ensuring your website exceeds expectations.</p>
                </div>
            </div>
            <div className="row">
                <div className="buttonWrapper">
                    <Link onClick={scrollToTop} className="btn btn-primary btn-custom" to="/services">Find out how</Link>
                </div>
            </div>
        </div>
    );
};

export default Benefits;
