import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './../styles/AdminSlide.css';

const AdminSlide = ({ slide, onDelete }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleEdit = () => {
    navigate(`/admin/slides/edit-slide/${slide.id}`);
  };

  // Determine background style based on slide type
  const getBackgroundStyle = (slide) => {
    let backgroundStyle = {};
    if (slide.slideType === 'solid-color') {
      backgroundStyle.backgroundColor = slide.color;
    } else if (slide.slideType === 'gradient') {
      backgroundStyle.background = slide.gradient;
    }
    return backgroundStyle;
  };

  // Parse CSS string to object with camelCase properties
  const parseForegroundStyling = (stylingString) => {
    if (!stylingString || stylingString === 'none') return {};

    const toCamelCase = (str) => {
      return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    };

    return stylingString.split(';').reduce((styles, style) => {
      const [property, value] = style.split(':').map(str => str.trim());
      if (property && value) {
        styles[toCamelCase(property)] = value;
      }
      return styles;
    }, {});
  };

  // Parse additional button styling string into an object
  const parseButtonStyling = (stylingString) => {
    if (!stylingString || stylingString === 'none') return {};

    const toCamelCase = (str) => {
      return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
    };

    return stylingString.split(';').reduce((styles, style) => {
      const [property, value] = style.split(':').map(str => str.trim());
      if (property && value) {
        styles[toCamelCase(property)] = value;
      }
      return styles;
    }, {});
  };

  // Button styles
  const buttonStyles = {
    backgroundColor: slide.buttonColor,
    color: slide.buttonTextColor,
    border: 0,
    ...parseButtonStyling(slide.buttonAdditionalStyling),
  };

  const hoverStyles = {
    backgroundColor: slide.buttonTextColor,
    color: slide.buttonColor,
    border: 0,
    ...parseButtonStyling(slide.buttonAdditionalStyling),
  };

  // Add glow effect if buttonGlowColor is not 'none'
  if (slide.buttonGlowColor && slide.buttonGlowColor !== 'none') {
    const glowColor = slide.buttonGlowColor;
    buttonStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}, 0 0 200px ${glowColor}, 0 0 250px transparent`;
    hoverStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}, 0 0 200px ${glowColor}, 0 0 250px ${glowColor}`;
  }

  return (
    <div className="admin-slide-container">
      {/* Action buttons container */}
      <div className="slide-actions">
        <button onClick={handleEdit} className="edit-btn">Edit</button>
        <button onClick={() => onDelete(slide.id)} className="delete-btn">Delete</button>
      </div>
      {/* Slide content */}
      <div className="admin-slide" style={{ backgroundColor: slide.baseColor }}>
        <div
          className="slide-background"
          style={{
            ...getBackgroundStyle(slide),
            ...parseForegroundStyling(slide.foregroundStyling),
          }}
        >
          {slide.slideType === 'image' && (
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/slides/${slide.image}`}
              alt="Slide"
              className="slide-image"
              style={{ width: '100%', height: '80vh' }}
            />
          )}
        </div>
        {slide.content && (
          <div className={`slide-content ${slide.slideAlign}`} style={{ marginTop: '15rem' }}>
            <h1>{slide.caption}</h1>
            <p>{slide.description}</p>
            {slide.button ? (
              <div className="admin-slide-button">
                <button
                  className="slide-button"
                  style={isHovered ? hoverStyles : buttonStyles}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  {slide.buttonText}
                </button>
                &nbsp;
                <div className="slide-button-link">{slide.buttonLink}</div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminSlide;
