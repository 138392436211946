import React from 'react';
import './Icon.css';

const DevelopmentIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(120,4450) scale(0.85,-0.85)" strokeWidth="95" fill="none">
            <path d="M1525 3160 c-127 -154 -231 -284 -233 -289 -2 -7 59 -84 356 -443 58 -71 108 -128 111 -128 4 0 30 39 59 86 34 55 50 90 44 97 -5 7 -76 95 -158 196 -82 102 -148 188 -147 193 2 4 73 94 159 199 l156 191 -54 89 c-29 49 -55 89 -58 89 -3 0 -109 -126 -235 -280z"/>
            <path d="M2379 3398 c-6 -24 -26 -92 -44 -153 -18 -60 -74 -252 -125 -425 -50 -173 -104 -358 -120 -410 -15 -52 -26 -98 -23 -102 6 -11 191 -10 197 1 6 9 62 191 94 306 11 39 68 233 126 431 58 199 106 369 106 378 0 14 -15 16 -100 16 l-99 0 -12 -42z"/>
            <path d="M2681 3356 c-28 -46 -51 -88 -51 -93 0 -4 70 -94 155 -198 85 -105 155 -193 155 -196 0 -3 -70 -91 -155 -195 -85 -104 -154 -193 -155 -199 0 -17 96 -170 107 -170 5 0 86 92 178 205 93 113 197 239 232 282 l63 76 -230 281 c-127 154 -235 283 -240 286 -5 3 -31 -33 -59 -79z"/>
        </g>
      </svg>
    </div>
  );
};

export default DevelopmentIcon;
