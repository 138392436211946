import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/icons/Logo';
import { Helmet } from 'react-helmet-async';

const NotFoundPage = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '10rem', marginBottom: '16rem' }}>
            <Helmet>
                <title>404 - Page Not Found - WattsWebWorks</title>
                <meta name="description" content="Sorry, the page you are looking for does not exist or couldn't be loaded. Go back to the homepage for more information." />
                <meta property="og:title" content="404 - Page Not Found - WattsWebWorks" />
                <meta property="og:description" content="Sorry, the page you are looking for does not exist or couldn't be loaded. Go back to the homepage for more information." />
                <meta property="og:url" content={typeof window !== 'undefined' ? window.location.href : ''} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="404 - Page Not Found - WattsWebWorks" />
                <meta name="twitter:description" content="Sorry, the page you are looking for does not exist or couldn't be loaded. Go back to the homepage for more information." />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>

            <div className='not-found-logo' style={{ textAlign: 'center', width: 'auto', height: 'auto' }}>
                <Logo
                    scaleMultiplier={1.5}
                    shouldAnimate={false}
                    hideLumens={true}
                    bulbColor={"black"}
                    networkColor={"black"}
                    wwwColor={"black"}
                />
            </div>

            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist or couldn't be loaded.</p>
            <p>You can always go back to the <Link to="/" style={{ color: '#e4a906', fontWeight: 700 }} aria-label="Homepage">homepage</Link>.</p>
        </div>
    );
}

export default NotFoundPage;
