import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './styles/BlogView.css';
import BlogCategories from '../components/BlogCategories';
import RecentBlogs from '../components/RecentBlogs';
import BlogTags from '../components/BlogTags';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from './NotFoundPage';
import axios from './../api/axios'; // Import the axios instance
import BlogContent from '../components/BlogContent';

const BlogView = () => {
  const [blogPost, setBlogPost] = useState(null);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { title } = useParams(); // Get the blog ID from the URL
  const [loading, setLoading] = useState(true);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); // Set loading to true before fetching data
      try {
        const response = await axios.get('/api/get-categories');
        setCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/get-tags');
        setTags(response.data);
      } catch (error) {
        console.error("Failed to fetch tags:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTags();
  }, []);

  useEffect(() => {
    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get('/api/recent-blogs');
        setBlogs(response.data);
        setRecentBlogs(response.data.slice(0, 3));
      } catch (error) {
        console.error("Failed to fetch blogs:", error);
      }
    };
    fetchRecentBlogs();
  }, []);

  useEffect(() => {
    const fetchBlogPost = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/get-blog/${title}`);
        if (response.status !== 200) {
          throw new Error('Blog not found');
        }
        setBlogPost(response.data);
      } catch (error) {
        console.error("Failed to fetch blog post:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchBlogPost();
  }, [title]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredBlogs = blogs.filter(blog => 
    blog.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
    new Date(blog.modified).toLocaleDateString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    }).includes(searchQuery)
  );

  if (error) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{blogPost ? `${blogPost.title} - ` : ""}Blog - WattsWebWorks</title>
        <meta name="description" content={blogPost ? blogPost.metaDesc : "Loading..."} />
        {blogPost && (
          <>
            <meta property="og:title" content={blogPost.title} />
            <meta property="og:description" content={blogPost.metaDesc} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="WattsWebWorks" />
            <meta property="og:type" content="article" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/uploads/blogs/${blogPost.image}`} />
            <meta property="og:image:alt" content={blogPost.imageAlt || "Blog post image"} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={blogPost.title} />
            <meta name="twitter:description" content={blogPost.metaDesc} />
            <meta name="twitter:image" content={`${process.env.REACT_APP_API_URL}/uploads/blogs/${blogPost.image}`} />
            <meta name="twitter:image:alt" content={blogPost.imageAlt || "Blog post image"} />
            <meta property="article:published_time" content={blogPost.published} />
            <meta property="article:modified_time" content={blogPost.modified} />
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "Article",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": window.location.href,
                },
                "headline": blogPost.title,
                "description": blogPost.metaDesc,
                "image": {
                  "@type": "ImageObject",
                  "url": `${process.env.REACT_APP_API_URL}/uploads/blogs/${blogPost.image}`,
                  "caption": blogPost.imageAlt || "Blog post image",
                },
                "datePublished": blogPost.published,
                "dateModified": blogPost.modified,
                "author": {
                  "@type": "Person",
                  "name": blogPost.author,
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "WattsWebWorks",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.wattswebworks.com/logo512.png",
                  },
                },
              })}
            </script>
          </>
        )}
      </Helmet>

<div className="blog-layout">
  <div className='blog-sidebar'>
    <div className="sidebar-wrapper">
      <div className='blog-view-search-wrapper'>
        <input
          id='blog-view-search'
          type='search' 
          placeholder={`Search blogs...`}
          value={searchQuery} 
          onChange={handleSearch} 
          aria-label="Search blogs"
        />
        <i className="fa-solid fa-magnifying-glass blog-search-icon" aria-hidden="true"/>
        {searchQuery && (
          <div className="search-results">
            <ul>
              {filteredBlogs.map(blog => (
                <Link key={blog.id} to={`/blog/${encodeURIComponent(blog.title)}`}>
                  <li>
                    {blog.title} - {new Date(blog.modified).toLocaleDateString('en-US')}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
      </div>

      <BlogCategories 
        categories={categories}
        isExpanded={expandedSection === 'categories'}
        onExpand={() => setExpandedSection('categories')}
        onCollapse={() => setExpandedSection(null)}
        initialLimit={3}
        loading={loading}
      />
      <RecentBlogs recentBlogs={recentBlogs} loading={loading} />
      <BlogTags 
        tags={tags}
        isExpanded={expandedSection === 'tags'}
        onExpand={() => setExpandedSection('tags')}
        onCollapse={() => setExpandedSection(null)}
        initialLimit={3}
        loading={loading}
      />
    </div>
  </div>
  <BlogContent blogPost={blogPost} loading={loading} />
</div>

    </>
  );
};

export default BlogView;

