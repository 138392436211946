import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import './../styles/AddUser.css';
import { useAuth } from '../../context/AuthProvider'; // Ensure this import
import axios from './../../api/axios';

function AddUser() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [loaded, setLoaded] = useState(false); // State to ensure data isn't re-fetched unnecessarily
    const [roleLevels, setRoleLevels] = useState({}); // State to store role levels
    const [roles, setRoles] = useState([]); // State to store roles

    const { auth } = useAuth(); // Get auth data from context

    const navigate = useNavigate();
    const { id: routeId } = useParams(); // Adjusted to get the id from the route

    const isEdit = Boolean(routeId); // Check if there is a user ID in the route to determine if it's an edit operation
    const formTitle = isEdit ? "Edit User" : "Add New User";

    useEffect(() => {
        if (isEdit && !loaded) {
            axios.get(`/api/get-user/${encodeURIComponent(routeId)}`)
                .then(response => {
                    const data = response.data;
                    setUsername(data.username);
                    setRole(data.role);
                    setLoaded(true); // Set to true to prevent refetching the data
                })
                .catch(error => console.error('Error fetching user:', error));
        }
    }, [isEdit, loaded, routeId]); // Updated to depend on routeId

    useEffect(() => {
        const fetchRoleLevels = async () => {
            try {
                const response = await axios.get('/api/get-role-levels');
                const data = response.data;
                const levels = data.reduce((acc, { role_name, security_level }) => {
                    acc[role_name] = security_level;
                    return acc;
                }, {});
                setRoleLevels(levels);
                setRoles(data.map(({ role_name }) => role_name));
            } catch (error) {
                console.error('Error fetching role levels:', error);
            }
        };

        fetchRoleLevels();
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        let userData = { username, role };
        if (password) {
            userData.password = password; // Only include password in userData if it's been set
        }

        // Check role security level before submitting
        if (auth.user.role !== 'siteadmin' && auth.user.security_level <= roleLevels[role]) {
            alert("You cannot assign a role with a higher or equal security level.");
            return;
        }

        const url = isEdit ? `${process.env.REACT_APP_API_URL}/api/edit-user/${encodeURIComponent(routeId)}` : `${process.env.REACT_APP_API_URL}/api/add-user`;
        axios({
            method: isEdit ? 'PUT' : 'POST',
            url: url,
            data: userData,
        })
        .then(() => navigate('/admin/users'))
        .catch(error => console.error('Error:', error));
    };

    return (
        <>
            <div className="formButtons">
                <Link to="/admin/users"><button className="cancelEdit">Cancel</button></Link>
            </div>
            <div className="add-user-container">  
                <div className="add-user-form">
                    <h2>{formTitle}</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Username:</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Enter username"
                                required
                            />
                        </div>
                        <div>
                            <label>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder={isEdit ? "Enter new password (optional)" : "Enter password"}
                                required={!isEdit}
                            />
                        </div>
                        <div>
                            <label>Role:</label>
                            <select
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                required
                            >
                                <option value="" disabled>Select role</option>
                                {roles.map((roleName) => (
                                    <option key={roleName} value={roleName}>
                                        {roleName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AddUser;
