import React, { useState, useEffect, useCallback, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/ContactForm.css';
import axios from './../api/axios';

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        preference: 'email',
        contact: '',
        message: '',
        contactTime: null,
    });

    const datePickerRef = useRef(null);
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [messageVisible, setMessageVisible] = useState(false);
    const [minDateTime, setMinDateTime] = useState('');
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date(2100, 0, 1));  // Setting a far future date as max time by default
    const [excludedTimes, setExcludedTimes] = useState([]);
    const [currentExcludedTimes, setCurrentExcludedTimes] = useState([]);

    const findNextAvailableTime = (startTime, bookedTimes) => {
        let proposedTime = new Date(startTime);
        while (bookedTimes.some(time => time.getTime() === proposedTime.getTime())) {
            proposedTime.setMinutes(proposedTime.getMinutes() + 30); // Increment by 30 minutes
            if (proposedTime.getHours() >= 21 && proposedTime.getMinutes() > 0) {
                proposedTime.setDate(proposedTime.getDate() + 1);
                proposedTime.setHours(9, 0, 0, 0);
            }
        }
        return proposedTime;
    };

    const getInitialDateTime = useCallback((bookedTimes) => {
        const now = new Date();
        const currentHour = now.getHours();
        if (currentHour >= 21) {
            now.setDate(now.getDate() + 1);
            now.setHours(9, 0, 0, 0);
        } else if (currentHour < 9) {
            now.setHours(9, 0, 0, 0);
        } else {
            now.setHours(now.getHours() + 1, 0, 0, 0);
        }
        return findNextAvailableTime(now, bookedTimes);
    }, []);

    useEffect(() => {
        const fetchBookedTimes = async () => {
            try {
                const response = await axios.get('/api/get-inquiry-timeslots');
                const data = response.data;
                const bookedTimes = data.map(item => {
                    const utcDate = new Date(item.contactTime);
                    const etOffset = utcDate.getTimezoneOffset() - 240; // Adjusting for Eastern Time
                    return new Date(utcDate.getTime() - etOffset * 60000);
                });
                setExcludedTimes(bookedTimes);
    
                // Set the initial datetime after getting booked times
                const initialTime = getInitialDateTime(bookedTimes);
                setFormData(prevState => ({
                    ...prevState,
                    contactTime: initialTime
                }));
    
                // Filter excluded times based on the initial time
                filterExcludedTimes(initialTime, bookedTimes);
            } catch (error) {
                console.error('Failed to fetch booked times:', error);
            }
        };
        fetchBookedTimes();
    }, [getInitialDateTime]);

    const filterExcludedTimes = (date, times) => {
        const filteredTimes = times.filter(time => 
            time.toDateString() === date.toDateString()
        );
        setCurrentExcludedTimes(filteredTimes);
    };

    const handleDateChange = (date) => {
        const newDateOnly = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const currentContactDateOnly = formData.contactTime && new Date(
            formData.contactTime.getFullYear(), 
            formData.contactTime.getMonth(), 
            formData.contactTime.getDate()
        );
    
        const isNewDate = !currentContactDateOnly || newDateOnly.getTime() !== currentContactDateOnly.getTime();
        const isTimeChanged = !formData.contactTime || 
            date.getHours() !== formData.contactTime.getHours() || 
            date.getMinutes() !== formData.contactTime.getMinutes();
    
        if (isNewDate) {
            const startOfDay = new Date(newDateOnly.setHours(9, 0, 0));
            const firstAvailableTime = findNextAvailableTime(startOfDay, excludedTimes);
            setFormData(prevState => ({
                ...prevState,
                contactTime: firstAvailableTime
            }));
            setMinTime(firstAvailableTime);
        } else if (isTimeChanged) {
            const selectedTime = new Date(newDateOnly.setHours(date.getHours(), date.getMinutes(), 0));
            const availableTime = findNextAvailableTime(selectedTime, excludedTimes);
            if (availableTime.getTime() !== formData.contactTime.getTime()) {
                setFormData(prevState => ({
                    ...prevState,
                    contactTime: availableTime
                }));
            }
        }
        setMaxTime(new Date(newDateOnly.setHours(21, 0, 0)));
        filterExcludedTimes(newDateOnly, excludedTimes);
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'preference'){
            setFormData(prevState => ({
                ...prevState,
                [name]: value,
                contact: ''
            }));
        } else if (name === 'contact' && formData.preference !== 'email') {
            const numbers = value.replace(/[^\d]/g, '');
            if (numbers.length <= 10) {
                let formattedNumber = '';
                if (numbers.length > 6) {
                    formattedNumber = `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`;
                } else if (numbers.length > 3) {
                    formattedNumber = `${numbers.slice(0, 3)}-${numbers.slice(3, 6)}`;
                } else {
                    formattedNumber = numbers;
                }
                setFormData(prevState => ({
                    ...prevState,
                    [name]: formattedNumber
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    useEffect(() => {
        if (submissionMessage) {
            setMessageVisible(true);
            const timer = setTimeout(() => {
                setSubmissionMessage('');
                setMessageVisible(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [submissionMessage]);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
    
        let offset = formData.contactTime.getTimezoneOffset() * 60000;
        let localDateTime = new Date(formData.contactTime.getTime() - offset);
        const formattedDate = localDateTime.toISOString().replace('T', ' ').substring(0, 19);
    
        try {
            const response = await axios.post('/api/new-inquiry', {
                ...formData,
                contactTime: formattedDate
            });

            if (response.status === 201) {
                setSubmissionMessage(<span>Inquiry sent successfully! We'll get back to you ASAP!<br/>We look forward to working with you.</span>);
                const newExcludedTimes = [...excludedTimes, formData.contactTime];
                setExcludedTimes(newExcludedTimes);
                filterExcludedTimes(formData.contactTime, newExcludedTimes);
                setFormData({
                    name: '',
                    preference: 'email',
                    contact: '',
                    message: '',
                    contactTime: getInitialDateTime(newExcludedTimes),
                });
            } else {
                throw new Error(response.data.message || 'Failed to submit form.');
            }
        } catch (error) {
            setSubmissionMessage('Failed to send inquiry. Please try again.');
            console.error(error);
        }
        setSubmitting(false);
    };
    
    useEffect(() => {
        const now = new Date();
        const currentHour = now.getHours();
        let startDate = new Date();
    
        if (currentHour >= 21) {
            startDate.setDate(startDate.getDate() + 1);
            startDate.setHours(9, 0, 0, 0);
        } else {
            startDate.setHours(9, 0, 0, 0);
        }
    
        const endDate = new Date(startDate);
        endDate.setHours(21, 0, 0, 0);
    
        setMinDateTime(startDate);
        setMinTime(startDate);
        setMaxTime(endDate);
    }, []);
    
    useEffect(() => {
        if (datePickerRef.current && datePickerRef.current.input) {
            datePickerRef.current.input.readOnly = true;
        }
    }, [datePickerRef, formData.contactTime]);

    return (
        <form className={`contactForm ${messageVisible ? 'messageVisible' : ''}`} onSubmit={handleSubmit}>
            <p className='info-note'>Your information will NOT be shared with anyone.</p>
            <div className='form-fields'>
                <div className="form-group input-with-icon">
                    <label htmlFor="name">Name <span className='labelNote'>(or Business/Company Name)</span></label>
                    <i className="fa-solid fa-signature icon"></i>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        placeholder="John Smith"
                        maxLength={45}
                    />
                </div>
                <div className="form-group input-with-icon">
                    <label htmlFor="preference">Preferred Contact Method:</label>
                    <i className="fa-solid fa-address-book"></i>
                    <select name="preference" value={formData.preference} onChange={handleChange} required>
                        <option value="email">Email</option>
                        <option value="call">Call</option>
                        <option value="text">Text</option>
                    </select>
                </div>
                <div className="form-group input-with-icon">
                    <label htmlFor="contact">{formData.preference === 'email' ? 'Email' : 'Phone'}</label>
                    <i className={formData.preference === 'email' ? 'fa-solid fa-at' : 'fa-solid fa-phone'}></i>
                    <input
                        type={formData.preference === 'email' ? 'email' : 'tel'}
                        id="contact"
                        name="contact"
                        value={formData.contact}
                        onChange={handleChange}
                        placeholder={formData.preference === 'email' ? 'example@gmail.com' : '555-555-5555'}
                        maxLength={formData.preference === 'email' ? 45 : 13}
                        inputMode={formData.preference === 'email' ? "text" : "tel"}
                        required
                    />
                </div>

                <div className="form-group input-with-icon">
                    <label htmlFor="message">What can we help with? <span className='labelNote'>(Be as detailed as possible)</span></label>
                    <i className="fa-solid fa-list-ol description-icon"></i>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        maxLength={8000}
                        required
                    ></textarea>
                </div>
                <div className="form-group input-with-icon">
                    <label htmlFor="contactTime">When can we contact you? <span className='labelNote'>(EST/EDT)</span></label>
                    <i className="fa-solid fa-calendar-days"></i>
                    <DatePicker
                        ref={datePickerRef}
                        className="picker"
                        placeholderText="Click to select date and time"
                        selected={formData.contactTime}
                        onChange={handleDateChange}
                        dateFormat="MMMM d, yyyy h:mm aa"
                        minDate={minDateTime}
                        minTime={minTime}
                        maxTime={maxTime}
                        showTimeSelect
                        timeFormat="hh:mm aa"
                        timeCaption="time"
                        timeIntervals={30}
                        excludeTimes={currentExcludedTimes}
                        required
                    />
                </div>
                <button type="submit" disabled={submitting}>Submit</button>
                {submissionMessage && (
                    <div className="submissionMessage" aria-live="polite">{submissionMessage}</div>
                )}
            </div>
        </form>
    );
}

export default ContactForm;
