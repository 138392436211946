import React from 'react';
import './Icon.css';

const DevelopmentIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(560,5890) scale(0.65,-0.65)" strokeWidth="80" fill="none">
            <path d="M2011 7375 c-576 -88 -1038 -514 -1171 -1079 -78 -330 -24 -710 144 -1010 193 -345 549 -605 946 -691 113 -24 372 -30 495 -11 416 64 789 314 1002 671 247 416 265 931 45 1363 -69 135 -159 257 -275 369 -203 197 -445 325 -719 378 -116 23 -350 28 -467 10z m379 -30 c223 -26 520 -153 695 -297 260 -215 431 -510 490 -845 19 -114 19 -334 0 -449 -47 -271 -171 -523 -353 -716 -160 -169 -348 -290 -568 -363 -137 -46 -230 -64 -375 -72 -294 -15 -586 67 -844 240 -109 74 -279 242 -353 351 -232 340 -302 766 -191 1162 91 325 320 627 604 796 277 165 578 230 895 193z"/>
            <path d="M2060 7298 c-83 -9 -236 -46 -318 -78 -436 -167 -757 -557 -837 -1014 -20 -115 -20 -335 1 -451 114 -663 707 -1136 1379 -1102 292 15 546 115 780 307 145 119 224 219 345 434 31 56 85 214 106 307 27 126 32 377 9 499 -20 108 -22 115 -51 204 -139 420 -492 747 -927 860 -162 42 -313 53 -487 34z m376 -43 c264 -46 497 -168 690 -360 172 -171 284 -366 347 -603 29 -112 31 -125 31 -317 0 -183 -3 -208 -26 -295 -71 -264 -184 -457 -371 -637 -499 -479 -1284 -479 -1783 -1 -196 189 -323 414 -380 678 -23 104 -26 400 -6 495 102 476 421 841 863 989 58 20 141 42 185 50 108 19 339 20 450 1z"/>
            <path d="M2156 6866 c-57 -21 -87 -47 -113 -96 -89 -167 95 -349 264 -262 25 13 55 40 70 64 24 35 28 52 28 109 0 59 -4 73 -30 110 -51 71 -143 103 -219 75z m131 -43 c21 -9 48 -36 65 -62 25 -39 29 -53 24 -89 -12 -88 -63 -142 -146 -150 -140 -13 -228 149 -140 260 43 55 130 73 197 41z"/>
            <path d="M2680 6488 c-52 -15 -158 -45 -235 -64 -225 -58 -302 -53 -630 41 -86 25 -169 45 -185 45 -16 0 -42 -11 -59 -26 -25 -21 -31 -33 -31 -66 0 -49 13 -74 48 -91 15 -8 124 -42 242 -77 118 -35 224 -71 234 -81 18 -17 19 -25 8 -156 -7 -76 -18 -161 -26 -189 -19 -72 -96 -278 -167 -445 -63 -152 -70 -192 -39 -231 31 -41 71 -52 114 -34 34 14 43 29 149 244 l113 229 92 -201 c114 -249 121 -261 166 -276 64 -21 130 29 128 97 0 15 -27 91 -60 168 -145 342 -178 453 -193 639 -10 130 -10 140 8 156 10 9 113 45 228 79 116 34 223 67 238 73 56 22 75 107 35 153 -39 43 -72 46 -178 13z m-881 -52 c207 -59 337 -86 413 -86 76 0 261 42 511 116 67 20 100 11 119 -31 10 -22 8 -30 -9 -53 -22 -29 -39 -36 -178 -78 -331 -98 -345 -106 -345 -200 0 -195 46 -372 178 -686 86 -206 89 -216 62 -251 -49 -61 -94 -25 -165 130 -80 175 -139 286 -157 297 -29 17 -54 -19 -155 -222 -52 -107 -104 -203 -114 -213 -27 -27 -64 -24 -89 8 -25 32 -23 57 13 136 31 68 128 312 162 407 37 106 53 188 60 325 10 175 36 156 -393 286 -126 38 -145 55 -133 121 1 6 49 37 60 38 2 0 74 -20 160 -44z"/>
        </g>
      </svg>
    </div>
  );
};

export default DevelopmentIcon;
