import React from 'react';

const FooterMap = () => {
  return (
    <div className="mapContainer">
      <iframe
        title="West Virginia Google Maps"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3179806.2429628666!2d-82.82173538472759!3d38.894722534656964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884a54a5d4cb0945%3A0xbb00c768decd3a43!2sWest%20Virginia!5e0!3m2!1sen!2sus!4v1714360023506!5m2!1sen!2sus"
        width="100%"
        height="100%"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        style={{ border: 0 }}
      ></iframe>
    </div>
  );
};

export default FooterMap;
