import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from './../../api/axios';
import { useAuth } from '../../context/AuthProvider';
import './../styles/UsersAdmin.css';
import AdminHeader from "./AdminHeader";

import RolesAdmin from "./RolesAdmin";

export default function UsersAdmin() {
    const [users, setUsers] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);
    const { auth } = useAuth();

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-users`);
        setUsers(response.data);
    };

    const deleteUser = async (id, userSecurityLevel) => {
        if (auth.user.role !== 'siteadmin' && auth.user.security_level <= userSecurityLevel) {
            alert("You cannot delete a user with higher or equal security level.");
            return;
        }
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-user/${id}`);
            setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));
        } catch (error) {
            console.error("Failed to delete user:", error);
        }
    };

    return (
        <div className="users-admin">
            <AdminHeader />
            <div className="formButtons">
                <Link to="/admin/users/new-user"><button className="add-user-btn">Add User</button></Link>
            </div>
            <table className="users-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Role</th>
                        <th>Security Level</th>
                        <th className="actions-column"></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={index} onMouseEnter={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>{user.security_level}</td>
                            <td className="actions">
                                {hoveredRow === index && (
                                    <>
                                        {(auth.user.id === user.id || auth.user.role === 'siteadmin' || auth.user.security_level > user.security_level) && (
                                            <Link to={`/admin/users/edit-user/${user.id}`}>
                                                <button className="edit-user-btn">
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </button>
                                            </Link>
                                        )}
                                        {auth.user.id !== user.id && (auth.user.role === 'siteadmin' || auth.user.security_level > user.security_level) && (
                                            <button className="delete-user-btn" onClick={() => deleteUser(user.id, user.security_level)}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>
                                        )}
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <RolesAdmin />
        </div>
    );
}
