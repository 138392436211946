import React from 'react';
import './styles/ServicesCard.css';

function ServicesCard({ icon, title, description }) {
    return (
      <div className="service-card" aria-label={title}>
        <div className="icon-container">{icon}</div>
        <h3 className='service-card-title'>{title}</h3>
        <p>{description}</p>
      </div>
    );
  }
  
  export default ServicesCard