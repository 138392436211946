import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/HeroCarousel.css';

const HeroCarousel = ({ slides, loading }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 480);
    const [hoveredButtonIndex, setHoveredButtonIndex] = useState(null);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth <= 480);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        beforeChange: (current, next) => handleSlideChange(next),
        afterChange: current => handleSlideChange(current),
    };

    const handleSlideChange = useCallback((index) => {
        const slickSlides = document.querySelectorAll('.slick-slide');
        slickSlides.forEach((slideElement, i) => {
            const adjustedIndex = (i + slides.length - 1) % slides.length; // Adjust the index
            const slide = slides[adjustedIndex];
            if (slide) {
                slideElement.style.backgroundColor = slide.baseColor;
            }
        });
    }, [slides]);

    // Initial background color setup
    useEffect(() => {
        handleSlideChange(0);
    }, [handleSlideChange]);

    // Determine background style based on slide type
    const getBackgroundStyle = (slide) => {
        let backgroundStyle = {};
        if (slide.slideType === 'solid-color') {
            backgroundStyle.backgroundColor = slide.color;
        } else if (slide.slideType === 'gradient') {
            backgroundStyle.background = slide.gradient;
        }
        return backgroundStyle;
    };

    // Parse the foreground styling string into an object
    const parseForegroundStyling = (stylingString, scale = 1) => {
        if (!stylingString || stylingString === 'none') return {};
        
        const toCamelCase = (str) => {
            return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        };

        const scaleStyleValue = (value) => {
            const number = parseFloat(value);
            const unit = value.replace(number, '');
            return (number / scale) + unit;
        };

        return stylingString.split(';').reduce((styles, style) => {
            const [property, value] = style.split(':').map(str => str.trim());
            if (property && value) {
                styles[toCamelCase(property)] = scale === 1 ? value : scaleStyleValue(value);
            }
            return styles;
        }, {});
    };

    // Parse additional button styling string into an object
    const parseButtonStyling = (stylingString) => {
        if (!stylingString || stylingString === 'none') return {};
        
        const toCamelCase = (str) => {
            return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
        };
        
        return stylingString.split(';').reduce((styles, style) => {
            const [property, value] = style.split(':').map(str => str.trim());
            if (property && value) {
                styles[toCamelCase(property)] = value;
            }
            return styles;
        }, {});
    };

    const getButtonStyles = useMemo(() => slides.map((slide) => {
        const baseButtonStyles = {
            backgroundColor: slide.buttonColor,
            color: slide.buttonTextColor,
            ...parseButtonStyling(slide.buttonAdditionalStyling),
        };

        const hoverButtonStyles = {
            backgroundColor: slide.buttonTextColor,
            color: slide.buttonColor,
            ...parseButtonStyling(slide.buttonAdditionalStyling),
        };

        if (slide.buttonGlowColor && slide.buttonGlowColor !== 'none') {
            const glowColor = slide.buttonGlowColor;
            baseButtonStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}, 0 0 200px ${glowColor}, 0 0 250px transparent`;
            hoverButtonStyles.boxShadow = `0 0 5px ${glowColor}, 0 0 25px ${glowColor}, 0 0 100px ${glowColor}, 0 0 200px ${glowColor}, 0 0 250px ${glowColor}`;
        }

        return { baseButtonStyles, hoverButtonStyles };
    }), [slides]);

    if (loading || !slides) {
        return (
            <div className="hero-carousel">
                <Skeleton height={'77vh'} width="100%" />
            </div>
        );
    }

    return (
        <div className="hero-carousel">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div
                            className="slide-background"
                            style={{
                                ...getBackgroundStyle(slide),
                                ...parseForegroundStyling(slide.foregroundStyling, isSmallScreen ? 3 : 1),
                            }}
                        >
                            {slide.slideType === 'image' && (
                                <img 
                                    src={slide.image} 
                                    alt="Slide" 
                                    className="slide-image" 
                                    onError={(e) => e.target.style.display = 'none'}
                                />
                            )}
                        </div>
                        {slide.content ? (
                            <div className={`slide-content ${slide.slideAlign || 'left'}`}>
                                <h1>{slide.caption}</h1>
                                <p>{slide.description}</p>
                                {slide.buttonLink && (
                                    slide.buttonLink.includes('#') ? (
                                        <HashLink
                                        to={slide.buttonLink}
                                        className="slide-button"
                                        style={hoveredButtonIndex === index ? getButtonStyles[index].hoverButtonStyles : getButtonStyles[index].baseButtonStyles}
                                        onMouseEnter={() => setHoveredButtonIndex(index)}
                                        onMouseLeave={() => setHoveredButtonIndex(null)}
                                        >
                                        {slide.buttonText}
                                        </HashLink>
                                    ) : (
                                        <Link
                                        to={slide.buttonLink}
                                        className="slide-button"
                                        style={hoveredButtonIndex === index ? getButtonStyles[index].hoverButtonStyles : getButtonStyles[index].baseButtonStyles}
                                        onMouseEnter={() => setHoveredButtonIndex(index)}
                                        onMouseLeave={() => setHoveredButtonIndex(null)}
                                        >
                                        {slide.buttonText}
                                        </Link>
                                    )
                                )}
                            </div>
                        ) : null}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default HeroCarousel;

