import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import axios from './../../api/axios';
import './../styles/AdminDashboard.css';
import AdminHeader from './AdminHeader';

const AdminDashboard = () => {
    const { auth } = useAuth();
    const [roleConfig, setRoleConfig] = useState({});
    const [configLoading, setConfigLoading] = useState(true);

    useEffect(() => {
        const fetchRoleConfig = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-route-config`);
                const config = response.data.reduce((acc, { route, roles }) => {
                    acc[route] = roles.split(',');
                    return acc;
                }, {});
                setRoleConfig(config);
                setConfigLoading(false);
            } catch (error) {
                console.error('Failed to fetch role configuration:', error);
            }
        };

        fetchRoleConfig();
    }, []);

    const checkAccess = (path) => roleConfig[path]?.includes(auth.user?.role);

    if (configLoading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <AdminHeader />
            <h1 className='admin-welcome'>Welcome {auth.user.username}!</h1>
            <div className='dashboard'>
                {checkAccess("/admin/inquiries") && (
                    <Link to='/admin/inquiries' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <p>Inquiries</p>
                            <i className="fa-solid fa-envelope" />
                            <i className="fa-solid fa-comments" />
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/slides") && (
                    <Link to='/admin/slides' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <p>Slides</p>
                            <i className="fa-regular fa-images" />
                            <i className="fa-solid fa-image" />
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/blogs") && (
                    <Link to='/admin/blogs' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <p>Blogs</p>
                            <i className="fa-regular fa-newspaper" />
                            <i className="fa-solid fa-quote-right" />
                        </div>
                    </Link>
                )}
                {checkAccess("/admin/users") && (
                    <Link to='/admin/users' className='dashboard-item'>
                        <div className='dashboard-item-graphic'>
                            <p>Users and Roles</p>
                            <i className="fa-solid fa-users" />
                            <i className="fa-solid fa-user-plus" />
                        </div>
                    </Link>
                )}
            </div>
        </>
    );
}

export default AdminDashboard;
