import React, { useState, useEffect } from 'react';
import '../styles/RolesAdmin.css';
import axios from './../../api/axios';
import { useAuth } from '../../context/AuthProvider';

const RolesAdmin = () => {
    const { auth } = useAuth();
    const [rolesData, setRolesData] = useState({});
    const [roleLevels, setRoleLevels] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [selectedRoute, setSelectedRoute] = useState('');
    const [newRoleName, setNewRoleName] = useState('');
    const [newRoleSecurityLevel, setNewRoleSecurityLevel] = useState('');
    const [hoveredRow, setHoveredRow] = useState(null); // Added for role levels table

    useEffect(() => {
        const fetchRolesData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-route-config`);
                const config = response.data.reduce((acc, { route, roles }) => {
                    acc[route] = roles.split(',');
                    return acc;
                }, {});
                setRolesData(config);
            } catch (error) {
                console.error('Failed to fetch role configuration:', error);
            }
        };

        const fetchRoleLevels = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-role-levels`);
                setRoleLevels(response.data);
            } catch (error) {
                console.error('Failed to fetch role levels:', error);
            }
        };

        fetchRolesData();
        fetchRoleLevels();
    }, []);

    const handleAddRole = async () => {
        if (newRole && selectedRoute) {
            if (!rolesData[selectedRoute].includes(auth.user.role)) {
                alert("You do not have access to this route.");
                return;
            }
            const roleLevel = roleLevels.find(roleLevel => roleLevel.role_name === newRole)?.security_level;
            if (auth.user.security_level < roleLevel) {
                alert("You cannot add a role with a higher security level.");
                return;
            }
            try {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/add-role-to-route`, { route: selectedRoute, role: newRole }, { withCredentials: true });
                setRolesData(prevRoles => {
                    const updatedRoles = { ...prevRoles };
                    if (!updatedRoles[selectedRoute].includes(newRole)) {
                        updatedRoles[selectedRoute].push(newRole);
                    }
                    return updatedRoles;
                });
                setNewRole('');
                setSelectedRoute('');
            } catch (error) {
                if (error.response && error.response.data) {
                    alert(error.response.data.error);
                } else {
                    console.error('Failed to add role:', error);
                }
            }
        }
    };

    const handleRemoveRole = async (route, roleToRemove) => {
        if (roleToRemove === 'siteadmin' || roleToRemove === auth.user.role) {
            return;
        }
        const roleLevel = roleLevels.find(roleLevel => roleLevel.role_name === roleToRemove)?.security_level;
        if (auth.user.security_level <= roleLevel) {
            alert("You cannot remove a role with a higher or equal security level.");
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/remove-role-from-route`, { route, role: roleToRemove });
            setRolesData(prevRoles => {
                const updatedRoles = { ...prevRoles };
                updatedRoles[route] = updatedRoles[route].filter(role => role !== roleToRemove);
                return updatedRoles;
            });
        } catch (error) {
            console.error('Failed to remove role:', error);
        }
    };

    const handleAddNewRole = async () => {
        if (!newRoleName || !newRoleSecurityLevel) {
            alert("Role name and security level are required.");
            return;
        }
        if (auth.user.security_level < newRoleSecurityLevel && auth.user.role !== 'siteadmin') {
            alert("You can only add roles with a security level equal to or lower than your own.");
            return;
        }
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/add-new-role`, { roleName: newRoleName, securityLevel: newRoleSecurityLevel });
            setNewRoleName('');
            setNewRoleSecurityLevel('');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-role-levels`);
            setRoleLevels(response.data);
        } catch (error) {
            console.error('Failed to add new role:', error);
        }
    };

    const handleDeleteRole = async (roleName) => {
        try {
            const roleLevel = roleLevels.find(role => role.role_name === roleName)?.security_level;
            if (auth.user.security_level <= roleLevel && auth.user.role !== 'siteadmin') {
                alert("You cannot delete a role with a higher or equal security level.");
                return;
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/api/delete-role`, { roleName });
            setRoleLevels(prevRoleLevels => prevRoleLevels.filter(role => role.role_name !== roleName));
        } catch (error) {
            if (error.response && error.response.data) {
                alert(error.response.data.error);
            } else {
                console.error('Failed to delete role:', error);
            }
        }
    };    

    return (
        <>
            <div className="roles-admin-container">
                <h2>Manage User Roles</h2>

                <div className="roles-table-wrapper">
                    <table className="roles-table">
                        <thead>
                            <tr>
                                <th>Route</th>
                                <th>Roles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(rolesData).map(([route, roles]) => (
                                <tr key={route}>
                                    <td>{route}</td>
                                    <td>
                                        {roles.map(role => (
                                            <span key={role} className={`role-tag ${role === 'siteadmin' ? 'siteadmin-tag' : ''}`}>
                                                {role}
                                                {role !== 'siteadmin' && role !== auth.user.role && (
                                                    auth.user.security_level > roleLevels.find(roleLevel => roleLevel.role_name === role)?.security_level ? (
                                                        <button className="delete-role-btn" onClick={() => handleRemoveRole(route, role)}>x</button>
                                                    ) : null
                                                )}
                                            </span>
                                        ))}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="role-add-form">
                    <select value={selectedRoute} onChange={(e) => setSelectedRoute(e.target.value)}>
                        <option value="">Select a route</option>
                        {Object.keys(rolesData).map(route => (
                            <option key={route} value={route}>{route}</option>
                        ))}
                    </select>
                    <input
                        type="text"
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                        placeholder="Enter new role"
                    />
                    <button onClick={handleAddRole}>Add Role</button>
                </div>
                {/* Role Levels Table */}
                <h2>Current Role Levels</h2>
                <div className="role-levels-table-wrapper">
                    <table className="roles-table">
                        <thead>
                            <tr>
                                <th>Role Name</th>
                                <th>Security Level</th>
                                <th className="role-actions-column"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {roleLevels.map(({ role_name, security_level }, index) => (
                                <tr key={role_name} onMouseEnter={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}>
                                    <td>{role_name}</td>
                                    <td>{security_level}</td>
                                    <td className="role-levels-actions">
                                        {hoveredRow === index && auth.user.security_level > security_level && (
                                            <button className="delete-user-btn" onClick={() => handleDeleteRole(role_name)}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="new-role-form">
                    <h2>Create a New Role</h2>
                    <input
                        type="text"
                        value={newRoleName}
                        onChange={(e) => setNewRoleName(e.target.value)}
                        placeholder="Enter role name"
                    />
                    <input
                        type="number"
                        value={newRoleSecurityLevel}
                        onChange={(e) => setNewRoleSecurityLevel(e.target.value)}
                        placeholder="Enter security level"
                    />
                    <button onClick={handleAddNewRole}>Add New Role</button>
                </div>
            </div>
        </>
    );
};

export default RolesAdmin;
