import React, { useEffect } from 'react';
import './styles/About.css';
import countryRoadsImage from '../assets/images/countryRoads.jpg';
import missionImage from '../assets/images/multipleWebsites.png';
import motivationImage from '../assets/images/growth.png';
import { Helmet } from 'react-helmet-async';
import ContactForm from './../components/ContactForm';

export default function About() {
    useEffect(() => {
        if (!('IntersectionObserver' in window)) {
            console.warn('IntersectionObserver is not supported by this browser.');
            // Fallback logic here if needed
            return;
        }
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('slide-up');
                    entry.target.querySelectorAll('img').forEach(img => {
                        img.classList.add('scale-up');
                    });
                } else {
                    entry.target.classList.remove('slide-up');
                    entry.target.querySelectorAll('img').forEach(img => {
                        img.classList.remove('scale-up');
                    });
                }
            });
        }, {
            threshold: 0.02
        });
    
        document.querySelectorAll('.about-page section').forEach(section => {
            observer.observe(section);
        });
    
        return () => observer.disconnect();
    }, []);
    
    
    return (
        <main>
            <Helmet>
                <title>About Us - WattsWebWorks</title>
                <meta name="description" content="Learn more about WattsWebWorks, our mission, and how we strive to deliver outstanding web solutions for our clients." />
                <meta property="og:title" content="About Us - WattsWebWorks" />
                <meta property="og:description" content="Learn more about WattsWebWorks, our mission, and how we strive to deliver outstanding web solutions for our clients." />
                <meta property="og:url" content="https://www.wattswebworks.com/about" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="WattsWebWorks" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Us - WattsWebWorks" />
                <meta name="twitter:description" content="Learn more about WattsWebWorks, our mission, and how we strive to deliver outstanding web solutions for our clients." />
                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "AboutPage",
                    "name": "About WattsWebWorks",
                    "url": "https://www.wattswebworks.com/about",
                    "description": "Learn more about WattsWebWorks, our mission, values, and the team behind our successful projects.",
                    "publisher": {
                    "@type": "Organization",
                    "name": "WattsWebWorks",
                    "logo": {
                        "@type": "ImageObject",
                        "url": "https://www.wattswebworks.com/logo512.png"
                    }
                    }
                })}
                </script>
            </Helmet>
            <div
                className="page-title"
                aria-label="About Us Page Title"
                style={{ backgroundImage: `url(${countryRoadsImage})` }}
            >
                <h1 className="title-text">About Us</h1>
            </div>

            <div className="about-page">
                <div className="section-background blue top">
                    <section className="mission-section" aria-label="Our Mission">
                        <div className="text-content">
                            <h2>OUR MISSION</h2>
                            <p>At WattsWebWorks, our mission is to ensure that every business has a presentable, functional, and responsive website along with a maximized digital presence geared towards reaching their target audience of potential clients.
                                Our focus is centered on blending creative insight with technical skill to deliver standout web solutions, using the latest technologies to build web experiences that resonate deeply with users and support our clients' goals for their business. 
                                We aim to exceed expectations, transforming every project into a testament to our commitment to excellence, and are dedicated to pushing the boundaries of web development, opening new doors for innovation, and ensuring our clients shine in the digital space.</p>
                        </div>
                        <div className="image-content">
                            <img src={missionImage} alt="Our Mission" loading='eager' />
                        </div>
                    </section>
                </div>
                
                <div className="section-background blue">
                    <section className="motivation-section" aria-label="What Motivates Us?">
                        <div className="image-content">
                            <img src={motivationImage} alt="Our Motivation" />
                        </div>
                        <div className="text-content">
                            <h2>OUR MOTIVATION</h2>
                            <p>Our motivation is led by an enduring passion for technology and its transformative impact on business landscapes, 
                                among many other aspects of our lives. At the heart of our endeavor lies a commitment to pushing boundaries and exploring new horizons. 
                                We see each challenge as a stepping stone towards innovation, leveraging our expertise to empower businesses with cutting-edge solutions. 
                                By closely collaborating with our clients, we unlock new possibilities, helping them navigate the complexities of the digital age with confidence. 
                                Our goal is to pave the way for sustainable growth and success, ensuring every digital footprint we create leaves a lasting impression.</p>
                        </div>
                    </section>
                </div>
                <div className='section-background grey'>
                    <h2 id="contact-heading">How to reach us</h2>
                    <section className="contact-section" id="contact" aria-labelledby="contact-heading">
                        <div className="contact-methods" aria-label="Contact Methods">
                            <div className="contact-method">
                                <a href="tel:304-936-2254" aria-label="Call us at 304-936-2254">
                                    <h3>Call or text</h3>
                                    <span>Anytime</span><br/>
                                    
                                    <i className="fa-solid fa-phone"></i>
                                    <p>304-936-2254</p>
                                </a>
                            </div>
                            <div className="contact-method">
                                <a href="mailto:contact@wattswebworks.com" aria-label="Email us at contact@wattswebworks.com">
                                    <h3>Email</h3>
                                    <span>Anytime</span><br/>
                                    
                                    <i className="fa-solid fa-at"></i>
                                    <p>contact@wattswebworks.com</p>
                                </a>
                            </div>
                        </div>
                        <div className="or-divider" aria-hidden="true">
                            <span className="or-text">OR</span>
                        </div>
                        <div className="contact-form" aria-label="Contact Form">
                            <h2>Submit this form and receive a <span>call,</span> <span>text,</span> or <span>email</span></h2>
                            
                            <ContactForm/>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    );
}

