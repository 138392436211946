import React from 'react';
import './Icon.css';

const CloudIcon = () => {
  return (
    <div className="icon-circle-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128"
        height="128"
        viewBox="0 0 4000 4000" // Adjusted viewBox
        className="svg-img"
      >
        <circle className="icon-circle" cx="2000" cy="2000" r="1300" fill="#f0f0f0" />
        
        <g className="icon-graphics" xmlns="http://www.w3.org/2000/svg" transform="translate(1050,2550) scale(0.65,-0.65)" strokeWidth="120" fill="none">
            <path xmlns="http://www.w3.org/2000/svg" d="M1695 1639 c-136 -11 -246 -59 -335 -147 -31 -31 -62 -66 -68 -76 -9 -18 -14 -16 -63 28 -92 83 -228 136 -349 136 -77 0 -181 -28 -270 -71 -232 -115 -352 -359 -275 -560 4 -11 -8 -14 -58 -14 -116 0 -218 -71 -253 -177 -20 -60 -20 -219 0 -275 43 -121 108 -198 210 -249 63 -31 84 -36 147 -37 73 -1 75 -1 123 -44 56 -50 166 -106 251 -128 98 -25 311 -20 428 9 53 14 115 33 139 44 39 17 47 18 83 5 77 -27 216 -53 318 -59 188 -12 378 25 537 104 95 47 96 47 150 35 30 -6 91 -12 135 -12 254 -3 422 143 445 386 21 233 -40 431 -176 570 -88 90 -152 118 -269 118 -72 0 -100 -5 -139 -23 -27 -12 -51 -21 -52 -19 -2 2 -8 28 -14 58 -17 91 -72 189 -145 257 -126 119 -270 159 -500 141z m247 -48 c99 -26 163 -59 230 -120 93 -85 138 -197 138 -346 0 -45 3 -85 7 -88 14 -14 33 17 33 55 0 38 2 40 58 66 228 105 456 -52 532 -369 65 -273 -34 -516 -237 -579 -67 -21 -182 -26 -272 -12 l-43 7 21 19 c12 11 21 25 21 32 0 28 -21 22 -67 -18 -61 -53 -203 -120 -313 -147 -217 -55 -498 -31 -714 59 -38 16 -73 27 -78 24 -18 -11 -6 -33 25 -44 41 -14 30 -21 -94 -56 -191 -52 -382 -43 -529 27 -73 34 -162 97 -155 109 2 4 19 10 36 14 22 4 30 11 27 23 -4 20 -5 20 -98 -2 -227 -53 -429 128 -430 386 0 163 98 269 251 269 53 0 66 -7 91 -51 13 -23 29 -39 40 -39 25 0 23 12 -11 67 -118 193 -57 421 149 558 174 116 375 136 539 54 71 -36 162 -123 203 -196 35 -61 42 -68 57 -50 8 10 3 25 -19 61 -17 26 -30 52 -30 59 0 26 78 113 137 152 56 38 155 77 223 88 70 12 202 6 272 -12z"/>
        </g>
      </svg>
    </div>
  );
};

export default CloudIcon;
