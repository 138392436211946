import React from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/RecentBlogs.css';

const RecentBlogs = ({ recentBlogs, loading }) => {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="blogSidebar-container recentBlogs">
      <div className='sidebar-container-content'>
        <h3><i className="fa-solid fa-clock"></i> Recent Blog Posts</h3>
        <ul>
          {loading ? (
            Array.from({ length: 2 }).map((_, index) => (
              <li key={index} className="skeleton-wrapper">
                <div className="skeleton-title">
                  <Skeleton height={10} width={'80%'} />
                  <Skeleton height={10} width={'60%'} />
                  <Skeleton height={10} width={'90%'} />
                </div>
                <div className="skeleton-date">
                  <Skeleton height={10} width={'100%'} />
                </div>
              </li>
            ))
          ) : (
            recentBlogs.map((recentBlog, index) => (
              <li key={index}>
                <Link to={`/blog/${encodeURIComponent(recentBlog.title)}`} className="blog-title-link" aria-label={`Read blog: ${recentBlog.title}`}>
                  <span className="blog-title">{recentBlog.title.slice(0, 33)}</span>
                </Link>
                <span className="blog-date">{formatDate(recentBlog.modified)}</span>
              </li>
            ))
          )}
        </ul>
        <Link to='/blog' className="more-blogs-btn">View More Blogs <i className="fas fa-arrow-right" aria-hidden="true"></i></Link>
      </div>
    </div>
  );
};

export default RecentBlogs;
