import React, { useState, useEffect } from "react";
import axios from './../../api/axios';
import '../styles/InquiriesAdmin.css';
import AdminHeader from "./AdminHeader";

export default function InquiriesAdmin() {
    const [inquiries, setInquiries] = useState([]);

    useEffect(() => {
        fetchInquiries();
    }, []);

    const fetchInquiries = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get-inquiries`, { withCredentials: true });
        setInquiries(response.data.map(inquiry => ({ ...inquiry, flipped: false })));
    };

    const deleteInquiry = async (id, event) => {
        event.stopPropagation();
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-inquiry/${id}`, { withCredentials: true });
        setInquiries(inquiries.filter(inquiry => inquiry.id !== id));
    };

    const toggleFlip = (id, event) => {
        event.stopPropagation();
        setInquiries(inquiries.map(inquiry =>
            inquiry.id === id ? { ...inquiry, flipped: !inquiry.flipped } : inquiry
        ));
    };

    //TODO ADD CURRENT TIME FOR EACH SUBMISSION

    return (
        <div className="inquiries-admin">
            <AdminHeader />
            <div className="inquiries-list">
                {inquiries.map(inquiry => (
                    <div key={inquiry.id} className={`inquiry-entry ${inquiry.flipped ? 'flipped' : ''}`}>
                        <div className="card-face card-front">
                            <p className="inquiry-id">{inquiry.id}</p>
                            <h2>{inquiry.name}</h2>
                            <p className="inquiry-contact-method"><span>{(inquiry.contactMethod).toUpperCase()}</span>: {inquiry.contact}</p>
                            <p>Contact On<br/>{new Date(inquiry.contactTime).toLocaleString()}</p>
                            <p className="inquiry-submit-time">Submitted on: <br/>{new Date(inquiry.submitTime).toLocaleString()}</p>
                            <button onClick={(e) => toggleFlip(inquiry.id, e)} className="flip-btn"><i className="fa-solid fa-repeat"></i></button>
                            <button onClick={(e) => deleteInquiry(inquiry.id, e)} className="delete-btn">Delete</button>
                        </div>
                        <div className="card-face card-back">
                            <h3>Description</h3>
                            <p className="inquiry-description">{inquiry.description}</p>
                            <button onClick={(e) => toggleFlip(inquiry.id, e)} className="flip-btn"><i className="fa-solid fa-repeat"></i></button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
