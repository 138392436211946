import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './../pages/styles/Blog.css';
import BlogCard from '../components/BlogCard';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';

export default function BlogTagView() {
  const [blogPostsData, setBlogPostsData] = useState([]);
  const { tag } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPostsByTag = async () => {
      try {
        const response = await axios.get(`/api/get-blogs/tag/${encodeURIComponent(tag)}`);
        setBlogPostsData(response.data);
        setError(null);
      } catch (error) {
        console.error("Failed to fetch blog posts by tag:", error);
        if (error.response && error.response.status === 404) {
          setError('No blogs found for the specified tag');
        } else {
          setError(error.message);
        }
      }
      setLoading(false);
    };

    fetchBlogPostsByTag();
  }, [tag]);

  const decodedTag = decodeURIComponent(tag);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <Helmet>
          <title>Tag: {decodedTag} - WattsWebWorks Blog</title>
          <meta name="description" content={`No blogs found for tag ${decodedTag}`} />
        </Helmet>
        <div className='blogs-tag-title'>
          Tag: &nbsp;<span className='tagName'>{decodedTag}</span>
        </div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className='blog-tag-view'>
      <Helmet>
        <title>Tag: {decodedTag} - WattsWebWorks Blog</title>
        <meta name="description" content={`Blogs tagged under ${decodedTag}`} />
        <meta property="og:title" content={`Tag: ${decodedTag} - WattsWebWorks Blog`} />
        <meta property="og:description" content={`Explore blogs tagged under ${decodedTag} on WattsWebWorks`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className='blogs-tag-title'>
        Tag: &nbsp;<span className='tagName'>{decodedTag}</span> {/* Decode the tag for display */}
      </div>
      <div className='all-categories-link'>
        <Link to="/blog">View All Tags</Link>
      </div>
      <div className='blogs'>
        {blogPostsData.map((post) => (
          <BlogCard key={post.id} {...post} />
        ))}
      </div>
    </div>
  );
}
