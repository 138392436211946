import React, { useEffect, useState, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthProvider';
import axios from './../api/axios';

const PrivateRoute = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [roleConfig, setRoleConfig] = useState({});
  const [configLoading, setConfigLoading] = useState(true);
  const [targetUserSecurityLevel, setTargetUserSecurityLevel] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchRoleConfig = async () => {
      if (auth.isAuthenticated) {
        setConfigLoading(true);
        try {
          const response = await axios.get('/api/get-route-config', { withCredentials: true });
          const config = response.data.reduce((acc, { route, roles }) => {
            acc[route] = roles.split(',');
            return acc;
          }, {});
          setRoleConfig(config);
        } catch (error) {
          console.error('Failed to fetch role configuration:', error);
        } finally {
          setConfigLoading(false);
        }
      } else {
        setConfigLoading(false);
      }
    };

    if (auth.isAuthenticated) {
      fetchRoleConfig();
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    const fetchTargetUserSecurityLevel = async () => {
      if (auth.isAuthenticated && location.pathname.includes('/admin/users/edit-user/') && id) {
        try {
          const response = await axios.get(`/api/get-user/${id}`, { withCredentials: true });
          setTargetUserSecurityLevel(response.data.security_level);
        } catch (error) {
          console.error('Failed to fetch target user security level:', error);
          setTargetUserSecurityLevel(null);
        }
      } else {
        setTargetUserSecurityLevel(null);
      }
    };

    if (auth.isAuthenticated) {
      fetchTargetUserSecurityLevel();
    }
  }, [auth.isAuthenticated, location.pathname, id]);

  const matchedRoles = useMemo(() => {
    return Object.keys(roleConfig).reduce((acc, key) => {
      if (location.pathname.startsWith(key)) {
        return roleConfig[key];
      }
      return acc;
    }, []);
  }, [roleConfig, location.pathname]);

  const userHasRequiredRole = matchedRoles.includes(auth.user?.role);
  const isEditingUser = location.pathname.includes('/admin/users/edit-user/');
  const userHasRequiredSecurityLevel = !isEditingUser || (auth.user.security_level > targetUserSecurityLevel) || (auth.user.id === parseInt(id, 10));

  if (configLoading || (isEditingUser && targetUserSecurityLevel === null)) {
    if (!auth.isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!userHasRequiredRole || (isEditingUser && !userHasRequiredSecurityLevel)) {
    return <Navigate to="/admin/dashboard" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
