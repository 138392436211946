import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ isAuthenticated: false, user: null });
  const [loading, setLoading] = useState(true); // Only for initial load
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await axios.get('/api/login', { withCredentials: true });
        if (response.data.loggedIn) {
          setAuth({ isAuthenticated: true, user: response.data.user });
          if (location.pathname === '/login') {
            navigate('/admin/dashboard');
          }
        } else {
          setAuth({ isAuthenticated: false, user: null });
        }
      } catch (error) {
        console.error('Session check error:', error);
        setAuth({ isAuthenticated: false, user: null });
        if (location.pathname.startsWith('/admin')) {
          navigate('/login');
        }
      }
      setLoading(false); // Ensure loading state is set to false at the end
    };

    checkSession();

    const interval = setInterval(checkSession, 5 * 60 * 1000); // Check session every 5 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [navigate, location.pathname]);

  const login = async (userData) => {
    try {
      const response = await axios.post('/api/login', userData, { withCredentials: true });
      if (response.data.user) {
        setAuth({ isAuthenticated: true, user: response.data.user });
        localStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/admin/dashboard');
        return response.data; // Return the response data
      } else {
        setAuth({ isAuthenticated: false, user: null });
        return { message: 'Login failed. Please try again.' }; // Return a failure message
      }
    } catch (error) {
      console.error('Login error:', error);
      setAuth({ isAuthenticated: false, user: null });
      return { message: 'Login failed. Please try again.' }; // Return an error message
    }
  };

  const logout = async () => {
    try {
      await axios.post('/api/logout', {}, { withCredentials: true });
      setAuth({ isAuthenticated: false, user: null });
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
