import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthProvider';
import './../styles/AdminHeader.css';

const AdminHeader = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <div className="admin-header">
      <div className="home-button-wrapper">
        <Link
          to="/admin/dashboard"
          className="home-button"
          style={{ display: location.pathname === '/admin/dashboard' ? 'none' : 'block' }}
          aria-label="Go to Admin Dashboard"
        >
          <i className="fa-solid fa-house"/>
          Home
        </Link>
      </div>
      <button onClick={handleLogout} className="logout-button" aria-label="Logout">
        Logout <i className="fa-solid fa-arrow-right-from-bracket"></i>
      </button>
    </div>
  );
};

export default AdminHeader;
