import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link } from 'react-router-dom';

const BlogContent = ({ blogPost, loading }) => {
  const renderBlogPostTags = () => {
    if (!blogPost.tags) return null;

    const tagsArray = blogPost.tags.split(',');

    return (
      <div className="blog-post-tags">
        {tagsArray.map((tag, index) => (
          <Link to={`/blog/tag/${encodeURIComponent(tag.trim())}`} key={index} style={{ textDecoration: 'none' }}>
            <span className="blog-tag" aria-label={`Tag: ${tag.trim()}`}>
              <i className="fa-solid fa-tag" aria-hidden="true"></i> {tag.trim()}
            </span>
          </Link>
        ))}
      </div>
    );
  };

  const renderBlogPostCategories = () => {
    if (!blogPost.categories) return null;

    const categoriesArray = blogPost.categories.split(',');

    return (
      <div className="blog-post-categories">
        {categoriesArray.map((category, index) => (
          <Link to={`/blog/category/${encodeURIComponent(category.trim())}`} key={index} style={{ textDecoration: 'none' }}>
            <span className="blog-category" aria-label={`Category: ${category.trim()}`}>
              <i className="fa-solid fa-list" aria-hidden="true"></i> {category.trim()}
            </span>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div className="blog-view">
      {loading ? (
        <div className="skeleton-wrapper">
          <Skeleton height={40} width="80%" />
          <Skeleton height={30} width="60%" style={{ margin: '0.5rem 0' }} />
          <Skeleton height={20} width="40%" style={{ margin: '0.5rem 0' }} />
          <Skeleton height={15} width="100%" style={{ margin: '1rem 0' }} count={5} />
        </div>
      ) : (
        blogPost && (
          <>
            <h1 className='title'>{blogPost.title}</h1>
            <p className='blog-meta'>{blogPost.metaDesc}</p>
            <div className='author-date'>
              By {blogPost.author} on
              <time className='date' dateTime={blogPost.date}>
                {new Date(blogPost.published).toLocaleDateString('en-US', {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                })}
              </time>
            </div>

            {renderBlogPostCategories()}

            <div className='blog-content' dangerouslySetInnerHTML={{ __html: blogPost.content }} />

            {renderBlogPostTags()}
          </>
        )
      )}
    </div>
  );
};

export default BlogContent;

