import React, { useEffect, useState } from 'react';
import './styles/ContactBar.css';
import { HashLink } from 'react-router-hash-link';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLocation } from 'react-router-dom';

const ContactBar = () => {
  const [isFixed, setIsFixed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeFixed = window.scrollY > 400;
      const isMobile = window.innerWidth <= 775;
      const scrollContactBar = ['/', '/about', '/services'].includes(location.pathname);
      setIsFixed(shouldBeFixed && !isMobile && scrollContactBar);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
      {isFixed && <div className="contactbar-placeholder"></div>}
      <div className={`contactbar ${isFixed ? 'contactbar-fixed' : ''}`}>
        <div className="contactBarContent">
          <div className="contact">
            <ul className="contactItems" aria-label="Contact information">
              <li className='phone'><a href="tel:304-936-2254" className="phone-number" aria-label="Call us at 304-936-2254"><i className="fa-solid fa-phone"></i><span className='contactItem-text'>304-936-2254</span></a></li>
              <li className='spacer'>|</li>
              <li className='facebookLink'><a href="https://www.facebook.com/WattsWebWorks" aria-label="Visit our Facebook page" rel="noopener noreferrer" target="_blank"><i className="fa-brands fa-facebook contactIcon"></i></a></li>
              <li className='spacer'>|</li>
              <li className='phone'><a href="mailto:contact@wattswebworks.com" aria-label="Email us at contact@wattswebworks.com" className="phone-number"><i className="fa-solid fa-envelope"></i><span className='contactItem-text'>contact@wattswebworks.com</span></a></li>
            </ul>
          </div>
          <div className='consultation'>
            <HashLink smooth to="/about#contact">Get a Quote</HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBar;
