import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './../pages/styles/Blog.css';
import BlogCard from '../components/BlogCard';
import axios from './../api/axios';
import { Helmet } from 'react-helmet-async';

export default function BlogCategoryView() {
  const [blogPostsData, setBlogPostsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { category } = useParams();

  useEffect(() => {
    const fetchBlogPostsByCategory = async () => {
      try {
        const response = await axios.get(`/api/get-blogs/category/${encodeURIComponent(category)}`);
        setBlogPostsData(response.data);
        setError(null); // Reset the error state
      } catch (error) {
        console.error("Failed to fetch blog posts by category:", error);
        if (error.response && error.response.status === 404) {
          setError('No blogs found for the specified category');
        } else {
          setError(error.message);
        }
      }
      setLoading(false);
    };

    fetchBlogPostsByCategory();
  }, [category]);

  const decodedCategory = decodeURIComponent(category);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        <Helmet>
          <title>Category: {decodedCategory} - WattsWebWorks Blog</title>
          <meta name="description" content={`No blogs found for category ${decodedCategory}`} />
        </Helmet>
        <div className='blogs-tag-title'>
          Category: &nbsp;<span className='tagName'>{decodedCategory}</span>
        </div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Category: {decodedCategory} - WattsWebWorks Blog</title>
        <meta name="description" content={`Blogs under category ${decodedCategory}`} />
        <meta property="og:title" content={`Category: ${decodedCategory} - WattsWebWorks Blog`} />
        <meta property="og:description" content={`Explore blogs under the category ${decodedCategory} on WattsWebWorks`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className='blogs-tag-title'>
        Category:&nbsp;<span className='tagName'>{decodedCategory}</span>
      </div>
      <div className='all-categories-link'>
        <Link to="/blog">View All Categories</Link>
      </div>
      <div className='blogs'>
        {blogPostsData.map((post) => (
          <BlogCard key={post.id} {...post} />
        ))}
      </div>
    </div>
  );
}
