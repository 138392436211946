import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import './styles/Admin.css';

function Admin() {
  return (
    <div className='adminWrapper'>
      <Helmet>
        <title>Admin - WattsWebWorks</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      
      <Outlet />
    </div>
  );
}

export default Admin;
