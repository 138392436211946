import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/BlogCategories.css';

const BlogCategories = ({ categories, isExpanded, onExpand, onCollapse, initialLimit, loading }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('auto');

  useEffect(() => {
    const calculateHeight = () => {
      if (loading) {
        setHeight(`${initialLimit * 28.6}px`); // Adjust height for 3 skeleton loaders
      } else if (isExpanded) {
        setHeight(`${contentRef.current.scrollHeight}px`);
      } else {
        const initialHeight = Array.from(contentRef.current.children)
          .slice(0, initialLimit)
          .reduce((acc, child) => acc + child.offsetHeight, 0);
        setHeight(`${initialHeight}px`);
      }
    };

    calculateHeight();

    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };
  }, [isExpanded, categories.length, initialLimit, loading]);

  return (
    <div className="blogSidebar-container">
      <div className='sidebar-container-content'>
        <h3><i className="fa-solid fa-list"></i> Categories</h3>
        {loading ? (
          <ul>
            {Array.from({ length: initialLimit }).map((_, index) => (
              <li key={index}>
                <Skeleton height={10} width={'50%'} />
              </li>
            ))}
          </ul>
        ) : (
          <ul 
            ref={contentRef} 
            style={{ height: height }}
            className={isExpanded ? 'expanded' : ''}
          >
            {(isExpanded ? categories : categories.slice(0, initialLimit)).map((category, index) => (
              <li key={index}>
                <Link to={`/blog/category/${encodeURIComponent(category)}`}>{category}</Link>
              </li>
            ))}
          </ul>
        )}
        {categories.length > initialLimit && (
          <div className="view-all-categories">
            {!isExpanded && (
              <button onClick={onExpand} className="expand-btn">
                <i className="fa-solid fa-arrow-down"></i> View All Categories <i className="fa-solid fa-arrow-down"></i>
              </button>
            )}
            {isExpanded && (
              <button onClick={onCollapse} className="expand-btn">
                <i className="fa-solid fa-arrow-up"></i> Collapse <i className="fa-solid fa-arrow-up"></i>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogCategories;
